.agents {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 852px;
  transform: translateX(-50%);

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;

      .text {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 18px);
        font-weight: 400;
        margin-right: 40px;
        text-align: center;
        cursor: pointer;

        .icon {
          width: 0;
          height: 0;
          transition: all 0.3s ease;
        }

        &.active {
          font-weight: bold;

          .icon {
            width: 18px;
            height: 8px;
          }
        }
      }
    }

    .seeAll {
      display: flex;
      align-items: center;
      color: var(--secondary-text);
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .agentsList {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: space-between;

    &.hidden {
      display: none;
    }

    .item {
      border-radius: 20px;
      background-color: var(--background-color);
      padding: 10px 16px;
      display: flex;
      align-items: center;
      width: 198px;
      box-sizing: border-box;
      margin-bottom: 20px;
      cursor: pointer;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 14px;
        overflow: hidden;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }

      .name {
        font-weight: 400;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--text-color);
      }
    }
  }

  .noData {
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;

    .nodataIcon {
      width: 130px;
      height: 130px;
    }

    .add {
      padding: 16px 30px;
      border-radius: 20px;
      background-color: var(--background-color);
      display: flex;
      align-items: center;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--text-color);
      cursor: pointer;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    .addIcon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}

.allAgents {
  border-radius: 30px;
  background-color: var(--background-color);
  padding: 30px 40px 10px 40px;
  width: 932px;

  .title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: calc(var(--font-size-add) + 22px);
      color: var(--text-color);
      font-weight: bold;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .navItem {
      border-radius: 28px;
      padding: 10px 18px;
      border: 1px solid var(--secondary-text);
      color: var(--text-color);
      cursor: pointer;
      margin-right: 20px;
      transition: all 0.3s ease;

      &.active {
        background-color: rgb(var(--primary-color));
        color: #fff;
        border-color: transparent;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 600px;
    transition: max-height 0.3s ease;

    .add {
      justify-content: center;

      .addIcon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .item {
      display: flex;
      margin-bottom: 20px;
      background-color: var(--secondary-bg);
      border-radius: 14px;
      width: 198px;
      padding: 10px 16px;
      align-items: center;
      height: 66px;
      box-sizing: border-box;
      cursor: pointer;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 14px;
        overflow: hidden;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}


.default {
  background-color: rgb(var(--secondary-text-rbg), 0.1);
}

.avatar_1 {
  background-color: rgba(var(--primary-color), 0.1);
}

.avatar_2 {
  background-color: rgba(255, 88, 82, 0.1);
}

.avatar_3 {
  background-color: rgba(9, 173, 25, 0.1);
}

.avatar_4 {
  background-color: rgba(255, 123, 0, 0.1);
}

.default,
.avatar_1,
.avatar_2,
.avatar_3,
.avatar_4 {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80% !important;
    height: 80% !important;
    object-fit: cover;
  }
}