.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 28px;
  bottom: 34px;
  right: 0px;
}

.previewBox {
  width: 50%;
  height: 100%;
}

.flexColumn {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.centerItemsbox {
  height: 31px !important;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;

  .title {
    height: 31px;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    margin-right: 10px;
  }

  .prompt {
    height: 31px;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FF7B00;
  }
}


.markmapclass {
  width: 100%;
  // border: 1px solid rgb(205, 205, 205);
  height: 100%;
  overflow: auto;
  margin-bottom: 10px;
  background-color: var(---, #f8faff);
  border-left: 1px solid #ddd;
}

.markmapclasstoolbar {
  position: absolute;
  bottom: -110px;
  right: 0;
  width: 100%;
  height: 112px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  // padding-bottom: var(--chakra-space-5);
}

.markmapclasstoolbar_preview {
  position: absolute;
  bottom: -0.75rem;
  right: -0.75rem;
  flex: 1;
  column-gap: 1vw;
  display: flex;
  flex-direction: row;
  padding-right: 2vw;
  padding-bottom: var(--chakra-space-5);
}

.buttonsty {
  width: 28px !important;
  height: 28px !important;
  margin: 0px 20px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
}

.dowbut {
  width: 92px !important;
  height: 40px !important;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 14px !important;
  margin-right: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

.bomdiv {
  height: 140px;
  width: 100%;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}