.imContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

.unLogin {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--secondary-text);
    margin-top: 20px;
  }
}