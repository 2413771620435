.loginContent {
  width: 428px;
  height: 520px;
  border-radius: 40px;
  padding: 45px 50px 60px;
  box-sizing: border-box;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;

  .loginHeader {
    padding: 15px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    text-align: center;
    line-height: 1;

    .text {
      cursor: pointer;
    }

    .icon {
      width: 0;
      height: 0;
      transition: all 0.3s ease;
    }

    & .active {
      color: rgb(var(--primary-color));
      font-weight: bold;

      .icon {
        width: 18px;
        height: 8px;
      }
    }
  }

  .content {
    flex: 1;
    width: 100%;

    .wx {
      width: 100%;
      text-align: center;

      .wximg {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 40px auto 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;

        .expired {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.95;
          background-color: var(--secondary-bg);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: calc(var(--font-size-add) + 14px);
          color: rgb(var(--primary-color));
          cursor: pointer;

          .loading {
            width: 30px;
            height: 30px;

            path {
              fill: rgb(var(--primary-color));
            }

            &.ing {
              animation: geting 0.5s ease-in;
            }
          }
        }
      }

      .wxQrcode {
        width: 180px;
        height: 180px;
      }

      .wxLogo {
        width: 130px;
        height: 130px;
        margin-top: 50px;
        margin-bottom: 47px;
        cursor: pointer;
      }

      .loginTips {
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }

    .byCode {
      margin-top: 20px;

      .phoneBtn {
        width: 100%;
        margin-top: 46px;
      }
    }

    .byPwd {
      margin-top: 20px;

      .underPwd {
        margin-top: 14px;
      }

      .toForgot,
      .bindPhone {
        text-align: right;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--secondary-text);
        cursor: pointer;
      }

      .loginBtn {
        width: 100%;
        margin-top: 12px;
      }
    }
  }

  .forgot,
  .bindPhone {
    position: relative;
    margin-top: -15px;

    .findTitle,
    .bindTitle {
      text-align: center;
      margin-bottom: 30px;
      position: relative;
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 18px);
      color: #000;

      .icon {
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .bindDesc {
      .freePoints {
        display: flex;
        align-items: center;
        color: rgb(var(--exp-color));
        font-size: calc(var(--font-size-add) + 16px);
        margin-bottom: 10px;

        svg {
          width: 12px;
          height: 12px;
          margin-left: 2px;
          margin-right: 2px;

          path {
            fill: rgb(var(--exp-color));
          }
        }
      }
    }

    .desc {
      color: var(--secondary-text);
      font-size: calc(var(--font-size-add) + 14px);
      margin-bottom: 20px;
    }

    .pwdNotSame {
      display: flex;
      position: absolute;
      bottom: 70px;
      align-items: center;

      .text {
        margin-left: 4px;
        color: #FF5852;
      }
    }
  }

  .forgetBtn,
  .bindBtn {
    width: 100% !important;
    margin-top: 45px;
  }

  .loginBottom {
    .travelUser {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;
      margin-bottom: 20px;
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--secondary-text);

      &.wx {
        margin-bottom: 42px;
      }

      &:hover {
        color: rgb(var(--primary-color));

        svg path {
          fill: rgb(var(--primary-color));
        }
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;


      }
    }

    .protocol {
      display: flex;
      align-items: flex-start;
      margin: 0 -20px;

      .agree {
        width: 18px;
        height: 18px;
        border: 2px solid var(--text-color);
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          background-color: rgb(var(--primary-color));
          border-color: transparent;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}

.phone {
  height: 60px;
  width: 330px;
  border-radius: 20px 20px 20px 20px;
  background-color: var(--secondary-bg);
  display: flex;
  align-items: center;

  .before {
    width: 65px;
    border-right: 1px solid rgba(var(--secondary-text-rbg), 0.2);
    text-align: center;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: rgb(var(--primary-color));
  }

  .input {
    border: none;
    outline: none;
    height: 100%;
    flex: 1;
    text-indent: 12px;
    background-color: transparent;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
  }

  .error {
    width: 100px;
    font-size: calc(var(--font-size-add) + 14px);
    color: #FF5852;
    padding-right: 15px;
    padding-left: 10px;
  }
}

.code {
  height: 60px;
  margin-top: 20px;
  width: 330px;
  display: flex;

  .inputCode {
    border: none;
    outline: none;
    width: 190px;
    height: 100%;
    border-radius: 20px;
    text-indent: 16px;
    background-color: var(--secondary-bg);
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
  }

  .getCode {
    border-radius: 20px;
    margin-left: 20px;
    flex: 1;
    font-size: calc(var(--font-size-add) + 14px);
    color: rgb(var(--primary-color));
    background-color: var(--secondary-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.pwd {
  height: 60px;
  width: 330px;
  border-radius: 20px 20px 20px 20px;
  background-color: var(--secondary-bg);
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
  box-sizing: border-box;

  .lock {
    width: 44px;
    text-align: center;

    .icon {
      width: 20px;
      height: 24px;
    }
  }

  .input {
    border: none;
    outline: none;
    height: 100%;
    flex: 1;
    background-color: transparent;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    padding-right: 10px;
  }

  .eyes {
    padding-right: 16px;

    .icon {
      width: 20px;
      height: 24px;
    }
  }

  .error {
    color: #FF5852;
    font-size: calc(var(--font-size-add) + 14px);
    position: absolute;
    right: 50px;
  }
}

@keyframes geting {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.protocolInfo {
  background-color: var(--background-color);
  width: 932px;
  height: 700px;
  border-radius: 30px;
  padding: 30px 20px 30px 30px;
  box-sizing: border-box;
  position: relative;

  .title {
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 22px);
    color: #000;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .content {
    height: 598px;

    .info {
      margin-right: 10px;
      font-size: calc(var(--font-size-add) + 16px);

      h5 {
        font-size: calc(var(--font-size-add) + 18px);
        font-weight: bold;
        color: #000;
        margin-bottom: 16px;
      }
    }
  }
}

.protocolContent {
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  flex: 1;

  .text {
    display: inline;
  }

  .wise {
    color: rgb(var(--primary-color));
    cursor: pointer;
    display: inline;
  }
}