.tool {
  display: contents;

  span {
    color: red;
  }
}

.tool input {
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
}

.tool textarea {
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
}

.contentBox {
  width: 100%;
  height: 100%;
}

.homecontent {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .nav {
    position: absolute;
    width: 20px;
    height: 100px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    transition: left 0.3s ease;

    &.hide {
      left: 0 !important;

      .navOpIcon {
        transform: translate(-50%, -50%) rotate(180deg) !important;
      }
    }

    .navBox {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;

      .navOp {
        width: 20px;
        filter: grayscale(100%) brightness(1.8) contrast(0.9);
      }

      .navOpIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}

.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  min-width: 556px;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.comsBox {
  display: contents;
  height: calc(100% - 85px);
  position: relative;
}

.comBox {
  flex-direction: column;
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 28px;
  bottom: 34px;
  right: 0px;
}

.previewBox {
  width: 50%;
  height: 100%;
}

.flex-container {
  order: 99;
  margin-top: 3px;
  padding: 1px 10px;
  width: 100%;
  background: #F3F6FF;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-top: 0;
    padding: 1px 5px;
    background: #fff;
  }
}

.text-styles {
  margin: 0 2px;
  color: rgba(86, 92, 250, 0.6);
}

.image-styles {
  width: 16px;
  height: 16px;
  margin-right: 1px;
}

.rotate-image-styles {
  transform: rotate(180deg);
}