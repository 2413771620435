.content {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
}

.allvipBox {
  display: contents;
  padding: 30px 0px;
  height: 100%;
  flex-direction: column;

  .vipinfo {
    padding: 27px 30px;
    display: flex;
    width: 540px;
    height: 76px;
    background: #E7E7E7;
    border-radius: 30px;
    justify-content: space-between;
    align-items: center;
    margin: 100px auto 50px;
    background-image: url('../../../../../../assets/png/personalVIP.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .vipbox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .avatar {
        width: 76px;
        height: 76px;
      }

      .titletext {
        font-weight: 900;
        font-size: 20px;
        color: #A9530C;
        margin-bottom: 5px;
        margin-left: 20px;
      }

      .desctext {
        font-weight: 400;
        font-size: 14px;
        color: #963C01;
        margin-left: 20px;
      }
    }

    .openbutton {
      width: 108px;
      height: 38px;
      background: linear-gradient(96deg, #DD9F26 0%, #B67B09 100%);
      border-radius: 36px 36px 36px 36px;
      border: 1px solid #F7F7F7;
      font-weight: bold;
      font-size: 16px;
      color: #FFF59C;
    }
  }

  .novip {
    padding: 27px 30px;
    display: flex;
    width: 540px;
    height: 76px;
    background: #E7E7E7;
    border-radius: 30px;
    justify-content: space-between;
    align-items: center;
    margin: 100px auto 50px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: url('../../../../../../assets/png/personalUnVIP.png');

    .vipbox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .avatar {
        width: 76px;
        height: 76px;
        border-radius: 50%;
      }

      .titletext {
        font-weight: 900;
        font-size: 20px;
        color: #333;
        margin-bottom: 5px;
        margin-left: 20px;
      }

      .desctext {
        font-weight: 400;
        font-size: 14px;
        color: #999;
        margin-left: 20px;
      }
    }

    .openbutton {
      width: 108px;
      height: 38px;
      background: linear-gradient(105deg, #999999 0%, #666666 100%);
      border-radius: 36px 36px 36px 36px;
      border: 1px solid #F7F7F7;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    }
  }

  .fatherbox {
    display: flex;
    width: 600px;
    margin: 0px auto;
    flex-direction: column;
    margin-bottom: 40px;

    .boxtext {
      height: 23px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 20px);
      color: var(--text-color);
      text-transform: none;
      margin-bottom: 30px;
    }

    .listbox {
      flex-direction: column;

      .rightbox {
        margin-bottom: 26px;
        align-items: center;

        .iconbox {
          width: 60px;
          height: 60px;
          background: #FFFFFF;
          border-radius: 18px;
          margin-right: 10px;
          align-items: center;
          justify-content: center;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .rightContentBox {
          flex-direction: column;

          .rightTitles {
            font-weight: bold;
            font-size: 18px;
            color: #333333;
          }

          .rightcontents {
            font-weight: 300;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }

    .table {
      width: 676px;
      height: 910px;
      background: #FFE7C4;
      border-radius: 20px;
      border-collapse: collapse;
      overflow: hidden;
    }

    table,
    th,
    td {
      border: 1px solid #fff;
    }

    tr,
    th {
      font-size: 16px;
      font-weight: bold;
    }

    th,
    td {
      color: #333;
      padding: 8px;
      text-align: center;
      align-items: center;
    }

    th {
      text-align: center;
    }

    .notviptext {
      color: #333333;
      background: #FFF4E3;
    }

    .viptext {
      font-weight: bold;
      color: #CA4900;
    }

    .vipBadge {
      margin-left: 3px;
      width: 38px;
      height: 14px;
    }

    .boldtext {
      font-size: 16px;
      font-weight: bold;
    }
  }
}


.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.child {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
  overflow-y: hidden;

  .selectDate {
    width: 400px;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 100px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: var(--background-color);
    border-radius: 20px;
    cursor: pointer;

    p {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
  }

  .listbox {
    flex: 1;
  }
}

.itmebox {
  width: 552px;
  height: 86px;
  padding: 20px 24px;
  background-color: var(--background-color);
  border-radius: 20px;
  margin-bottom: 20px;
  flex-shrink: 0;

  .billbox {
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }

  .billsboxicon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 0px 0px 0px 0px;
  }

  .billstitle {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
  }

  .billsboxawait {
    position: absolute;
    right: 0px;
    top: 30px;
    font-weight: bold;
    color: #FF7B00;
    font-size: calc(var(--font-size-add) + 14px);
  }

  .billsboxfail {
    position: absolute;
    right: 0px;
    top: 30px;
    font-weight: bold;
    color: #FF5852;
    font-size: calc(var(--font-size-add) + 14px);
  }

  .billsboxsucceed {
    position: absolute;
    right: 0px;
    top: 30px;
    font-weight: bold;
    color: #09AD19;
    font-size: calc(var(--font-size-add) + 14px);
  }

  .billsboxadd {
    width: 72px;
    height: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: #FF7B00;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 70px;
  }

  .billsboxdeplete {
    width: 72px;
    height: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 18px);
    color: #565CFA;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 70px;
  }


  .billsboxprices {
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 20px);
  }

  .paysbutton {
    width: 60px;
    height: 25px;
    background: #FFFFFF;
    border-radius: 27px 27px 27px 27px;
    border: 1px solid #999999;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 60px;
    cursor: pointer;
  }

  .textbox {
    padding: 5px 20px;
    align-items: center;

    .fasttext {
      width: 250px;
      height: 18px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
      line-height: 19px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .endtext {
      height: 18px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
      line-height: 19px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    svg {
      width: 22px;
      height: 22px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.noChat {
  height: 100%;
  margin-top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 18px;
    color: rgba(86, 92, 250, 0.3);
  }
}

.cmodelbox {
  width: 610px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px 0px;
  flex-direction: column;

  .cmodel_closebox {
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: bold;
      font-size: 22px;
      color: #333333;
    }

    .cmodel_close {
      height: 20px !important;
      width: 20px !important;
      cursor: pointer;
    }
  }

  .loadicon {
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    left: 55px;
    top: 70px;
    animation: loading 1s ease infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .payimage {
    width: 128px !important;
    height: 128px !important;
    margin-bottom: 20px;
    margin: 30px 20px 30px 40px;
  }

  .titletext {
    font-weight: bold;
    font-size: 32px;
    color: #FF2922;
    margin-bottom: 10px;

    span {
      font-size: 20px;
    }
  }

  .payimgbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .rechargesBox {
    flex-wrap: wrap;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  }

  .rechargebox {
    width: 130px;
    height: 140px;
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid #FFDAA2;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    .rechargeTagBox {
      position: absolute;
      left: 0px;
      top: -10px;
      width: 70px;
      height: 26px;
      background: linear-gradient(270deg, #FFDE69 0%, #FFC166 100%);
      border-radius: 20px 0px 14px 0px;
      align-items: center;
      justify-content: center;

      p {
        font-weight: bold;
        font-size: 12px;
        color: #7D3C00;
        text-align: center;
      }
    }

    .rechargeTitle {
      font-weight: 500;
      font-size: 14px;
      color: #793C04;
    }

    .rechargeNewprice {
      font-weight: bold;
      font-size: 26px;
      color: #333333;

      span {
        font-size: 16px;
      }
    }

    .rechargePrice {
      font-weight: 500;
      font-size: 14px;
      color: #DAAF88;
      text-decoration: line-through;
    }
  }

  .rechargebox:last-child {
    margin-right: 0px;
  }

  .modelbuttonbox {
    margin: 30px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .Savebutton {
    background: #03AD14;
    color: var(--text-reverse-color);
    width: 270px !important;
    border-radius: 36px !important;
    margin-left: 10px;
    margin-bottom: 20px;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }

  .checkbox_custom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox_custom text {
    font-weight: 400;
    font-size: 14px;
  }

  .checkbox_container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .checkbox_customs {
    width: 12px;
    height: 12px;
    border: 2px solid #333;
    border-radius: 50%;
    margin-right: 8px;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .checkbox_customs.checked {
    background-color: #333;
    border-color: #333;
  }

  .checkbox_custom Box {
    display: flex;
    align-items: center;
  }

  .checkbox_custom Box * {
    margin-left: 5px;
  }

  .agreement_text {
    color: #565CFA;
    /* 链接颜色 */
    cursor: pointer;
    /* 鼠标悬停时显示指针 */
  }

  .selected {
    background: #FFF2D9;
    border: 2px solid #FF9900;

    .rechargeNewprice {
      color: #FF2922;
    }
  }
}