.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  min-width: 556px;
  flex-direction: column;
  position: relative;
  background-color: #fff;

  .createBox {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .createText {
      width: 520px;
      align-items: flex-start;
      font-weight: bold;
      font-size: 22px;
      color: #333333;
    }

    .modelBox {
      width: 470px;
      height: 92px;
      padding: 0px 26px;
      background: #F5F5F5;
      border-radius: 16px;
      margin-bottom: 30px;
      align-items: center;
      cursor: pointer;
      position: relative;

      .tagBox {
        position: absolute;
        right: 0px;
        top: -10px;
        width: 50px;
        height: 30px;
        background-image: url('../../../assets/png/ppttags.png');
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        font-size: 13px;
        padding: 3px 3px 0px;
      }

      svg {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .titletext {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
      }

      .contentstext {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }

  .outlineBox {
    width: 100%;
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .createText {
      width: 520px;
      align-items: flex-start;
      font-weight: bold;
      font-size: 22px;
      color: #333333;
    }

    .textbox {
      width: 556px;
      flex-direction: column;
      font-weight: bold;
      font-size: 16px;
      color: #CCCCCC;
      margin-bottom: 10px;

      p {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
      }
    }

    .chapterBox {
      width: 556px;
      display: flex;
    }

    .more {
      width: 24px;
      height: 24px;
      fill: #333;
      cursor: pointer;
    }

    .libox {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .contentMore {
        width: 20px;
        height: 20px;
        fill: #999;
        cursor: pointer;
      }
    }

    .chaptertitle {
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }

    li {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
  }
}

.previewBox {
  width: 50%;
  height: 100%;
}

.footbutton {
  background-color: #fff;
  width: 100%;
  position: absolute;
  border-top: 1px solid #DDDDDD;
  padding-top: 20px;
  bottom: 0px;
  right: 0px;
  justify-content: center;

  .create {
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 442px;
      height: 60px;
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 16px);
      line-height: 20px;

      .start {
        margin-right: 6px;
      }

      .price {
        display: flex;
        align-items: center;

        svg {
          margin-left: 3px;
          width: 16px;
          height: 16px;

          path {
            fill: #fff;
          }
        }
      }
    }

    .vipbtn {
      width: 442px;
      height: 59px;
      background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%);
      border-radius: 20px;

      .vipprice {
        display: flex;
        align-items: center;

        svg {
          width: 22px;
          height: 22px;
        }

        p {
          margin-left: 5px;
          height: 22px;
          font-weight: bold;
          font-size: 16px;
          color: #A54500;
        }
      }

    }

    .notvipbut {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .vprice {
        display: flex;
        align-items: center;
        margin: 5px 0px;
        text-decoration: line-through;

        p {
          font-weight: 400;
          font-size: 14px;
          color: #999;
        }

        span {
          font-weight: bold;
          font-size: 14px;
          color: #999;
        }

        svg {
          margin-left: 3px;
          width: 11px;
          height: 11px;

          path {
            fill: #999
          }
        }
      }

      .price {
        display: flex;
        align-items: center;
        margin: 5px 0px;

        p {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }

        .pricetext {
          color: #fff;
          font-weight: bold;
        }

        span {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }

        svg {
          margin-left: 3px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.uploadBoxx {
  width: 516px;
  height: 130px;
  background: #F5F5F5;
  border-radius: 18px;
  margin: 30px 5px 0px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #999999;

  img {
    width: 90px !important;
    height: 90px !important;
    border-radius: 18px;
    margin-right: 15px;
  }

  .loader {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    margin: 0px 30px;
    border-radius: 50%;
    border-top: 4px solid transparent;
    animation: spin 1s linear infinite;
    position: relative;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .defaultbox {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    background: #DDDDDD;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .uploadIcon {
      width: 32px;
      height: 32px;
    }

    .errplusIcon {
      font-weight: 500;
      font-size: 14px;
      color: #FF5852;
    }
  }

  .previewVideo {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }

  .SucessBox {
    justify-content: start;
    align-items: center;
    margin-top: 15px;

    svg {
      width: 14px;
      height: 14px;
    }

    .errsvg {
      width: 22px;
      height: 22px;
      margin-right: 3px;
    }

    .againText {
      font-weight: 500;
      font-size: 16px;
      color: #565CFA;
    }
  }

  .textWithEllipsis {
    width: 200px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }

  .uploadSucess {
    font-weight: 400;
    font-size: 14px;
    color: #09AD19;
  }

  .uploadBoxicon {
    width: 28px !important;
    height: 28px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.moreEdit {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  font-size: calc(var(--font-size-add) + 14px);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  width: 140px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 999;

  .moreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .outlineItem {
    padding: 12px 4px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .deletemoreItem {
    padding: 12px 10px;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #FF2922
      }
    }

    p {
      margin-left: 5px;
      width: 56px;
      font-weight: 500;
      font-size: 14px;
      color: #FF2922;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.editTitle {
  padding: 20px;
  border-radius: 30px;
  background-color: var(--background-color);
  width: 1100px;
  height: 800px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 20px;

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .scrollContainer {
    width: 100%;
    max-height: calc(100% - 150px);
    flex-wrap: wrap;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: center;

    img {
      width: 400px;
      height: 225px;
      border-radius: 16px;
      margin: 20px;
    }
  }

  .scrollContainer::-webkit-scrollbar {
    display: none;
  }

  .btns {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    div {
      width: 500px;
      height: 48px;
      background: #565CFA;
      border-radius: 33px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }
  }
}