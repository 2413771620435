.creationImgItem {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;

  .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }

    .songlyricBox {
      width: 132px;
      height: 34px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 24px 24px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      backdrop-filter: blur(2px);
      padding: 10px 14px;

      p {
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
      }
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 15px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .favorite:hover {
      svg path {
        fill: rgb(var(--primary-color));
      }
    }

    .more:hover {
      circle {
        fill: rgb(var(--primary-color));
      }
    }
  }

  .view {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.suno {
    width: 160px;
    height: 160px;

    .mask {
      padding: 12px;

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}

.imgContent {
  display: flex;
  justify-content: space-between;
}

.imgBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.fail {
  height: 158px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  line-height: 18px;

  svg {
    width: 100px;
    height: 100px;
  }

  &.Midjourney {
    height: 120px;
  }

  &.StableDiffusion,
  &.StableDiffusion3 {
    height: 160px;

    svg {
      width: 56px;
      height: 56px;
    }
  }

  &.GEN3 {
    height: 186px;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin-top: 14px;

    .btn {
      width: 72px;
      height: 28px;
      font-size: calc(var(--font-size-add) + 12px);
      border-radius: 21px;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.risk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    width: 40px;
    height: 40px;
  }

  .riskText {
    color: #FF5852;
    margin-top: 10px;
  }

  &.Midjourney {
    height: 120px;
  }

  &.StableDiffusion,
  &.StableDiffusion3 {
    height: 160px;
  }
}

.process {
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 14px);
  color: #09AD19;
  line-height: 16px;

  p {
    margin-top: 5px;
  }
}