:root {
  --chat-nav-width: 340px;
  --chat-LR-padding: 27px;
}

.history {
  height: 100%;
  overflow: hidden;
  width: var(--chat-nav-width);
  border-right: 1px solid var(--border-color);

  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  &.hide {
    width: 0;
  }
}

.addContainer {
  padding: 30px 40px;
}

.addNewChat {
  background: rgba(var(--primary-color), 0.1);
  border-radius: 20px;
  width: 260px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--primary-color));
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 18px);
  cursor: pointer;

  .svg {
    width: 30px;
    height: 30px;
    margin-right: 6px;
  }
}

.list {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: var(--nav-width);

  .start {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--chat-LR-padding);
    align-items: center;
    margin-bottom: 20px;

    .title {
      color: var(--text-color);
      font-weight: bold;
      font-size: calc(var(--font-size-add) + 20px);
    }

    .operate {
      display: flex;
      align-items: center;

      .loading {
        margin-right: 20px;
        width: 22px;
        height: 22px;
      }

      .delete {
        display: flex;
        align-items: center;
        color: #FF5852;
        margin-right: 22px;
        cursor: pointer;

        .icon {
          width: 22px;
          height: 22px;
        }
      }

      .cancel {
        color: var(--secondary-text);
        cursor: pointer;
      }
    }

    .editContainer {
      display: flex;
      align-items: center;

      &.disable {
        opacity: 0.5;
      }
    }

    .edit {
      color: var(--text-color);
      cursor: pointer;
      margin-left: 4px;
    }
  }

  .chatList {
    flex: 1;
    height: 100%;

    .header {
      display: flex;
      height: 100px;
      cursor: pointer;
      align-items: center;
      padding: 20px var(--chat-LR-padding);
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      border: none;

      &.active {
        background: linear-gradient(90deg, rgba(var(--primary-color), 0.1) 0%, rgba(var(--primary-color), 0) 100%) !important;
      }

      &:hover {
        background: var(--secondary-bg);

        .arrow,
        .more {
          display: block;
        }
      }

      .headerContent {
        display: flex;
        align-items: center;

        .modelIcon {
          width: 60px;
          height: 60px;
          border-radius: 20px;
          margin-right: 14px;
        }

        .info {
          text-align: left;
          flex: 1;

          .name {
            font-size: calc(var(--font-size-add) + 18px);
            color: var(--text-color);
            margin: 0;
            font-weight: 500;
          }

          .count {
            font-size: calc(var(--font-size-add) + 14px);
            color: var(--secondary-text);
            margin-top: 4px;
            font-weight: 400;
          }
        }
      }

      .check {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid rgb(var(--primary-color));
        position: absolute;
        box-sizing: border-box;
        right: var(--chat-LR-padding);

        .icon {
          width: 20px;
          height: 20px;
        }

        &.checked {
          border: none;
        }
      }

      .arrow,
      .more {
        position: absolute;
        right: var(--chat-LR-padding);
        display: none;
        color: var(--secondary-text);
        font-size: 20px;
      }

      .arrow {
        transition: all 0.3s ease;
      }

      .arrowExpanded {
        transform: rotate(90deg);
      }
    }

    .item {
      background: #F9F9F9;
      padding: 15px calc(var(--chat-LR-padding) - 10px) 15px var(--chat-LR-padding);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease;

      &.fixTop {
        background: var(--secondary-border-color);
      }

      &:hover {
        background: var(--element-acitve-bg);
      }

      &.active {
        color: rgb(var(--primary-color));
        background: #e9eaff;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background: #7D82FF;
        }
      }


      .top {
        position: absolute;
        left: 6px;
      }

      .time {
        width: 46px;
        height: 46px;
        border-radius: 14px;
        text-align: center;
        padding: 6px 0;
        box-sizing: border-box;

        .dayType {
          font-weight: bold;
          font-size: calc(var(--font-size-add) + 16px);
          color: #fff;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        .date {
          font-weight: bold;
          font-size: calc(var(--font-size-add) + 16px);
          color: #fff;
          margin: 0;
        }

        .month {
          font-size: calc(var(--font-size-add) + 10px);
          color: #fff;
          margin: 0;
        }
      }

      .title {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: 500;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;
      }

      .hm {
        font-size: calc(var(--font-size-add) + 12px);
        color: var(--secondary-text);
        margin-left: 20px;
        margin-right: 10px;
      }

      .more {
        font-size: calc(var(--font-size-add) + 14px);
        padding: 6px 10px;
      }
    }

    .noChat {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: rgba(var(--primary-color), 0.3);
      font-size: calc(var(--font-size-add) + 18px);
      margin-top: -100px;
    }

    .moreOperation {
      position: fixed;
      background-color: var(--background-color);
      border-radius: 20px;
      box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
      padding: 10px 30px;
      z-index: 29;

      .op {
        padding: 16px 0;
        width: 142px;
        border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.3);
        cursor: pointer;
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: 400;
        color: var(--text-color);
        position: relative;

        svg {
          position: absolute;
          top: 18px;
          left: -24px;
          width: 20px;
          height: 20px;
        }

        &:hover {
          opacity: 0.8;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      ;
    }
  }
}

.editTitle {
  padding: 20px;
  border-radius: 16px;
  background-color: var(--background-color);
  width: 360px;
  height: 180px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(var(--font-size-add) + 20px);
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 20px;

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  input {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0 10px;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    background-color: var(--element-bg);
  }

  .btns {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}

.balance {
  padding: 0 var(--chat-LR-padding);
  margin-bottom: 40px;

  .num {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dot {
      font-size: calc(var(--font-size-add) + 18px);
      font-weight: bold;
      color: var(--text-color);

    }

    .recharge {
      background-color: rgb(var(--exp-color));
      color: #fff;
      width: 64px;
      height: 32px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;
      font-size: calc(var(--font-size-add) + 14px);
    }
  }

  .getMore {
    display: flex;
    align-items: center;
    cursor: not-allowed;
    margin-top: 8px;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--secondary-text);

    .icon {
      height: 12px;
      width: 12px;
    }
  }
}

.avatar {
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70% !important;
    height: 70% !important;
    object-fit: cover;
    margin-right: 0 !important;
  }
}