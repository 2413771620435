.flexColumn {
  background: #f9f9f9;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.centerItemsbox {
  height: 31px !important;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  border-left: 1px solid #DDDDDD;

  .title {
    height: 31px;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    margin-right: 10px;
    white-space: nowrap;
  }

  .prompt {
    height: 31px;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FF7B00;
  }
}

.fullbox {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullnobox {
  height: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fullHeightbox {
  height: 100%;
  // margin: 19px 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-left: 1px solid #DDDDDD;

  svg {
    width: 150px;
    height: 150px;
  }

  .prompt {
    color: #999;
  }

  .notextarea {
    height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: #f9f9f9;
    resize: none;
  }

  .textarea {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    width: calc(100% - 61px);
    height: calc(100% - 30px);
    border: 0px;
    padding: 30px 30px 0px;
    background-color: #f9f9f9;
    resize: none;
  }

  .nomarkdownBox {
    height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: #f9f9f9;
  }

  .markdownBox {
    width: calc(100% - 61px);
    height: calc(100% - 30px);
    padding: 30px 30px 0px;
    z-index: 3;

    svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
    }

    div {
      user-select: text;
    }
  }
}

.loadingBox {
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 14px;
    color: rgba(153, 153, 153, 0.5);
  }
}

.text {
  &.title {
    font-size: 16px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  &.prompt {
    font-size: 14px;
    color: #f7a955;
  }

  &.loading {
    color: rgba(153, 153, 153, 0.5);
    font-size: 14px;
  }
}

.button {
  height: 40px;
  width: 92px;
  color: #333;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  pointer-events: all;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(110, 110, 110, 0.24);
  margin-right: 1rem;

  svg {
    margin-top: 1px;
    margin-right: 3px;
  }

  &.disabled {
    color: #999;
    pointer-events: none;
    background-color: rgba(153, 153, 153, 0.2);
    box-shadow: none;
  }
}

.button:hover {
  color: #565CFA;
}

.footBox {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 36px 30px;
}

.footTextBox {
  align-items: center;
}

.buttonBox {
  display: flex;
  justify-content: space-between;
  position: relative;

  .aitext {
    position: absolute;
    right: 15px;
    bottom: -20px;
    font-size: 10px;
    font-weight: bold;
    color: #999;
  }
}

.contentsflex {
  background-color: #f9f9f9;
  font-size: 14px;
  color: #333333;
  width: calc(100% - 61px);
  height: calc(100% - 30px);
  border: 0px;
  padding: 30px;
}

// Modal.module.scss
.modalOverlayCustom {
  background-color: rgba(0, 0, 0, 0.5); // 改变遮罩背景颜色
}

.modalContentCustom {
  background-color: #fff; // 改变内容区域背景颜色
  border-radius: 8px; // 添加圆角
  padding: 20px; // 增加内边距
}

.modalHeaderCustom {
  font-size: 18px; // 改变标题文字大小
  color: #333; // 改变标题文字颜色
  border-bottom: 1px solid #e5e5e5; // 增加底部边框
  padding-bottom: 10px; // 标题底部内边距
}

.modalBodyCustom {
  font-size: 16px; // 改变正文文字大小
  color: #666; // 改变正文文字颜色
  margin: 10px 0; // 增加正文的外边距
}

.modalFooterCustom {
  border-top: 1px solid #e5e5e5; // 增加顶部边框
  padding-top: 10px; // 添加顶部内边距
  display: flex;
  justify-content: flex-end; // 右对齐按钮
}

.cancelButton {
  background-color: #f5f5f5; // 改变取消按钮背景颜色
  color: #333; // 改变取消按钮文字颜色
  border: none; // 移除边框
  padding: 10px 15px; // 增加内边距
  border-radius: 4px; // 圆角
  cursor: pointer; // 鼠标悬浮样式
}

.cancelButton:hover {
  background-color: #e0e0e0; // 悬浮状态下背景颜色
}

.confirmButton {
  background-color: #007bff; // 改变确认按钮背景颜色
  color: #fff; // 改变确认按钮文字颜色
  border: none; // 移除边框
  padding: 10px 15px; // 增加内边距
  border-radius: 4px; // 圆角
  cursor: pointer; // 鼠标悬浮样式
}

.confirmButton:hover {
  background-color: #0056b3; // 悬浮状态下背景颜色
}

.avatarcmodelbox {
  width: 310px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px;
  flex-direction: column;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }
}

.cmodelTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.avatar_group {
  width: 100%;
  font-weight: bold;
  font-size: 22px;
  margin-top: 20px;
  justify-content: center;
}

.modelbuttonbox {
  margin-top: 30px;
  justify-content: end;
  align-items: center;
}

.confirmButton {
  background: #565CFA;
  color: var(--text-reverse-color);
  width: 130px;
  margin-left: 10px;
}

.cancelButton {
  background-color: transparent;
  width: 130px;
  border: 1px solid #999;
}

.download {
  position: absolute;
  right: 280px;
  bottom: 280px;

  .downloadlist {
    background-color: var(--background-color);
    border-radius: 20px;
    box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
    padding: 10px 30px;
    z-index: 29;
  }

  .op {
    padding: 16px 10px;
    border: 0px;
    border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.3);
    cursor: pointer;
    font-size: calc(var(--font-size-add) + 16px);
    font-weight: 400;
    color: var(--text-color);
    background-color: var(--background-color);
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}