.toolbox {
  width: calc(100% - 60px);
  height: 39px;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
}

.databox {
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    background: rgba(241, 244, 255, 0.5);
    border-radius: 10px;
    margin-right: 10px;
  }

  p {
    font-weight: 900;
    font-size: 20px;
  }
}

.muteBox {
  justify-content: center;
  align-items: center;

  svg {
    width: 28px;
    height: 28px;
    margin-right: 15px;
    cursor: pointer;
  }
}

.hisbut {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  cursor: pointer;
}

.background {
  background-color: rgba(255, 255, 255, 0.3);
}

.hiscontent {
  max-width: 565px;
  min-width: 100px;
}

.hisflex {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 60px);
  padding: 30px 0px;
  background: #FFFFFF;
  box-shadow: -2px 0px 20px 0px rgba(0, 7, 70, 0.1);
  border-radius: 0px;
  transition: all 0.3s ease;
}

.histitle {
  width: calc(100% - 60px);
  margin: 0px 30px;
  margin-top: 10px;
  font-weight: 900;
  font-size: 20px;
  color: #333333;
  margin-bottom: 40px;
}

.comsBox {
  display: contents;
  // height: calc(100% - 85px);
  height: 100%;
  position: relative;
}

.hismodel {
  width: 465px;
  height: 124px;
  margin: 0px auto;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.single_line_text {
  width: 465px;
  font-weight: 400;
  font-size: 16px;
  // color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singles_line_text {
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0px;
  // color: #999999;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: 2.4em;
}

.deletebox {
  width: 465px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  p {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }

  svg {
    width: 26px;
    height: 26px;
  }

  .aitext {
    position: absolute;
    right: 0px;
    bottom: -14px;
    font-size: 10px;
    font-weight: bold;
    color: #999;
  }
}