.reference {
  .title {
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      margin-left: 6px;
      cursor: pointer;
    }
  }

  &.isFirst .titleBox {
    margin-top: 0;
  }

  .container {
    display: flex;
    margin-top: 20px;

    .img {
      width: 110px;
      height: 110px;
      border-radius: 20px;
      background-color: var(--secondary-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }

      .clear {
        position: absolute;
        top: 11px;
        right: 11px;
        width: 24px;
        height: 24px;
        background-color: var(--tips-text);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg path {
          fill: #fff;
        }
      }

      .text {
        font-weight: 500;
        font-size: calc(var(--font-size-add) + 14px);
        color: var(--secondary-text);
        line-height: 16px;
        margin-top: 9px;
      }
    }

    .slider {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 8px;
      flex: 1;

      .number {
        font-weight: 500;
        font-size: calc(var(--font-size-add) + 16px);
        color: var(--text-color);
        line-height: 19px;
      }

      .range {
        width: 100%;
      }
    }
  }
}

.sliderStyle {
  position: relative;
  color: red;
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

.tipsFooter {
  margin-top: 30px;
  text-align: right;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}

.tipsContent {
  font-size: calc(var(--font-size-add) + 14px);
  line-height: 20px;
  color: var(--secondary-text);
  margin-top: 20px;
  max-width: 400px;
}

.sliderRail {
  display: block;
  position: absolute;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(var(--secondary-text-rbg), 0.2);
}

.sliderMark {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 99%;
  transform: translateX(-50%);
}

.sliderTrack {
  display: block;
  position: absolute;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(90deg, #A1A4FF 0%, #565CFA 100%);
}

.sliderMarkLabel {
  font-family: IBM Plex Sans;
  font-weight: 500;
  line-height: 16px;
  font-size: calc(var(--font-size-add) + 14px);
  position: absolute;
  top: 24px;
  margin-top: 12px;
  color: var(--secondary-text);
  white-space: nowrap;
}

.sliderMarkLabel[data-index="0"] {
  left: 0;
}

.sliderMarkLabel[data-index="1"] {
  right: 0;
  left: auto !important;
}

.sliderThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: -6px;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  border: 2px solid rgb(var(--primary-color));
  background-color: #fff;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  transition-duration: 120ms;
  transform-origin: center;
}

.sliderValueLabel {
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
  line-height: 19px;
  transform: translateY(-100%);
  display: flex;
  align-items: center;
  flex-direction: column;
}