.prompt {

  .title {
    display: flex;
    justify-content: space-between;

    .text {
      display: flex;
      align-items: center;
      font-size: calc(var(--font-size-add) + 18px);

      .require {
        color: #FF2922;
        margin-left: 6px;
      }
    }

    .op {
      display: flex;
      align-items: center;
    }

    .optimization,
    .delete {
      display: flex;
      align-items: center;
      font-size: calc(var(--font-size-add) + 14px);
      color: #666666;
      cursor: pointer;
      margin-left: 18px;

      &.disabled {
        cursor: not-allowed;
      }

      .icon {
        margin-right: 5px;
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }

    .delete:hover {
      color: rgb(var(--primary-color));

      path {
        fill: rgb(var(--primary-color));
      }
    }

    .optimization:hover {
      color: rgb(var(--primary-color));

      path {
        fill: rgb(var(--primary-color));
        stroke: rgb(var(--primary-color));
      }
    }
  }

  .inputs {
    width: 100% !important;
    height: 50px;
    background-color: var(--secondary-bg);
    border-radius: 20px;
    border: 1px solid transparent;
    padding: 14px;
    box-sizing: border-box;
    font-size: calc(var(--font-size-add) + 16px);
    position: relative;
    border: 1px solid transparent;

    &:focus-within {
      border-color: rgb(var(--primary-color));
    }

    .area {
      border: none;
      background-color: transparent;
      width: 100%;
      height: 100%;
      font-size: calc(var(--font-size-add) + 14px);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }

    .length {
      position: absolute;
      right: 36px;
      bottom: 16px;
      font-size: calc(var(--font-size-add) + 14px);
      color: #666666;
    }
  }
}

.notbalancebox {
  width: 384px;
  background: #FFFFFF;
  flex-direction: column;
  padding: 24px 30px;
  border-radius: 30px;
  position: relative;
}

.notbalanceTitleBox {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }
}

.texttips {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  padding: 0px 10px;
}

.modelbuttonbox {
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
}

.Savebutton {
  background: transparent;
  color: #333;
  width: 80px !important;
}