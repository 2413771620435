.inputArea {
  padding: 26px;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(245, 245, 245, 0.86);
  z-index: 9;

  .container {
    padding: 10px 0;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 24px;
    position: relative;

    &:focus-within {
      border-color: rgb(var(--primary-color));
    }

    .uploadTypes {
      position: absolute;
      top: -60px;
      width: 138px;
      padding: 0 14px;
      background: var(--background-color);
      border-radius: 20px;
      cursor: not-allowed;
      height: 0px;
      overflow: hidden;
      transition: all 0.3s ease;
      box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);


      &.clsLevel1 {

        &.show {
          height: 105px;
          top: -135px;
        }
      }

      &.clsLevel2 {
        &.show {
          height: 155px;
          top: -185px;
        }
      }

      &.clsLevel3 {
        &.show {
          height: 210px;
          top: -240px;
        }
      }


      &.show {
        height: 85px;
        top: -115px;
      }

      .item {
        padding: 14px 8px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(var(--secondary-text-rbg), 0.4);
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:last-of-type {
          border-bottom: none;
          cursor: pointer;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        &.audio {
          svg path {
            fill: var(--text-color);
          }
        }
      }
    }
  }

  .inputContainer {
    width: 820px;
    overflow: hidden;
    max-height: 296px;
    box-sizing: border-box;
    padding: 4px 140px 4px 60px;
    display: flex;

    .ms-container {
      width: 100%;
    }

    .userInput {
      flex: 1;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      .fileList {
        margin-bottom: 15px;
        position: sticky;
        top: 0;
        display: flex;

        .fileItem {
          width: 90px;
          height: 90px;
          border-radius: 14px;
          position: relative;
          background: var(--secondary-bg);
          margin-right: 30px;

          &.max {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
              width: 26px;
              height: 26px;
              margin-bottom: 4px;
            }

            .maxText {
              width: 60px;
              font-weight: 400;
              text-align: center;
              color: var(--secondary-text);
              font-size: calc(var(--font-size-add) + 12px);
            }
          }

          .closeIcon {
            position: absolute;
            width: 20px;
            height: 20px;
            top: -5px;
            right: -5px;
          }

          .img {
            width: 100%;
            height: 100%;
            border-radius: 14px;
            object-fit: cover;
          }
        }

        .docFileItem {
          width: 214px;
          height: 100px;
          background-color: #fff;
          border: 1px solid #EEEEEE;
          position: relative;
          padding: 16px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 14px;
          margin-right: 30px;

          .closeIcon {
            position: absolute;
            width: 20px;
            height: 20px;
            top: -5px;
            right: -5px;
          }

          .docName {
            color: var(--text-color);
            font-size: calc(var(--font-size-add) + 14px);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
          }

          .fileType {
            display: flex;
            align-items: center;
            color: var(--secondary-text);
            font-size: calc(var(--font-size-add) + 16px);

            svg {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }
        }
      }

      .quote {
        width: 100%;
        background-color: var(--secondary-bg);
        border-radius: 16px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 16px 40px 16px 16px;
        box-sizing: border-box;
        margin-bottom: 15px;
        position: relative;

        p {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: calc(var(--font-size-add) + 14px);
          color: var(--tips-text);
          padding-right: 10px;
          border-right: 1px solid rgba(var(--secondary-text-rbg), 0.3);
          line-height: 1;
        }

        .delete {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .uploading,
  .addFile {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 20px;
    bottom: 15px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }


  .input {
    width: 100%;
    padding: 5px 0;
    line-height: 22px;
    max-height: 196px;
    box-sizing: border-box;
    font-size: calc(var(--font-size-add) + 16px);
    flex: 1;
    white-space: pre-wrap;
    word-break: break-all;
    overflow-y: scroll;

    &.hasFile {
      max-height: 96px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(var(--secondary-text-rbg), 0.5);
      border-radius: 11px;
    }

    &:empty::before {
      content: attr(content);
      color: #555;
    }
  }

  .micphone {
    position: absolute;
    right: 80px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .call {
    position: absolute;
    bottom: -4px;
    right: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .send {
    position: absolute;
    box-shadow: 0px 4px 10px 0px rgba(var(--sencondary-color), 0.4);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
    background: linear-gradient(90deg, #5344FF 0%, #9C8BFF 88%);
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      background: rgba(var(--secondary-text-rbg), 0.5);
      cursor: not-allowed;
    }

    .generatingIcon {
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 4px;
      animation: zoomStopIcon 0.5s infinite alternate;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }
}


@keyframes zoomStopIcon {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.1);
  }
}

.onlyMedia {

  .model {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-bg);
    border-radius: 16px;
    height: 54px;
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 20px;
    padding: 12px 16px;
    width: 100%;
    cursor: pointer;

    &.active {
      background-color: rgba(var(--primary-color), 0.1);

      .name,
      .price,
      .key {
        color: rgb(var(--primary-color)) !important;

        svg {
          path {
            fill: rgb(var(--primary-color));
          }
        }
      }
    }

    &:hover {
      background-color: rgba(var(--primary-color), 0.1);
    }

    .info {
      display: flex;
      align-items: center;

      .icon {
        box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
        border-radius: 8px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: calc(var(--font-size-add) + 14px);
        font-weight: bold;
        white-space: nowrap;
        margin-left: 8px;
        margin-right: 8px;
      }

      .key {
        font-weight: 400;
        margin-left: 4px;
        color: var(--secondary-text);
      }

      .recommend {
        border-radius: 10px 15px 15px 0px;
        background-color: rgb(var(--primary-color));
        width: 44px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .free {
        border-radius: 10px 15px 15px 0px;
        background: #FF7B00;
        width: 66px;
        height: 22px;
        color: #fff;
        font-size: calc(var(--font-size-add) + 12px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .price {
      display: flex;
      align-items: center;
      color: var(--secondary-text);

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: var(--secondary-text);
        }
      }
    }
  }
}

.TipsBox {
  background-color: var(--background-color);
  border-radius: 30px;
  padding: 24px;
  min-width: 440px;
  max-width: 440px;
  box-sizing: border-box;

  .titlebox {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .alertTitle {
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--text-color);
      font-weight: bold;
    }
  }

  .titleheadbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .titleClosebut {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }

  .tipsContent {
    margin-top: 20px;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);

    .item {
      margin-bottom: 14px;
      position: relative;
      padding-left: 25px;

      &::before {
        content: '';
        position: absolute;
        left: 8px;
        top: 8px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--text-color);
      }
    }
  }

  .noTip {
    display: flex;
    color: var(--secondary-text);
    font-size: calc(var(--font-size-add) + 16px);
    align-items: center;
    margin-top: 20px;
    padding-left: 25px;

    .gou {
      width: 16px;
      height: 16px;
      border: 1px solid var(--secondary-text);
      border-radius: 50%;
      margin-right: 6px;
      position: relative;
      cursor: pointer;

      .icon {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: rgb(var(--primary-color));
        }
      }
    }

    .text {
      cursor: pointer;
    }
  }

  .tipsFooter {
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
  }
}

.changeModelBtn {
  margin: 20px auto 0;
  display: flex !important;
  width: 287px !important;
}