.content {
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--secondary-bg);
  position: relative;
  padding-bottom: 112px;
  justify-content: center;

  .info {
    width: 600px;
    padding: 0 30px;
    margin: 80px auto 0;

    .params {
      margin-bottom: 30px;
    }

    .avatarBox {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      .avatar {
        width: 110px;
        height: 110px;
        border-radius: 30px;
        background-color: rgba(var(--primary-color), 0.1);
        position: relative;
        cursor: pointer;

        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .camera {
          position: absolute;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: -6px;
          right: -6px;
          background-color: rgb(var(--primary-color));
          border-radius: 50%;
          cursor: pointer;

          .icon,
          .loading {
            width: 20px;
            height: 20px;

            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .inputBox {
      width: 100%;
      position: relative;

      .input {
        border: 1px solid transparent;
        width: 100%;
        height: 60px;
        border-radius: 20px;
        padding: 16px;
        box-sizing: border-box;
        font-size: calc(var(--font-size-add) + 16px);

        &:focus {
          border-color: rgb(var(--primary-color));
        }
      }

      .tips {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0, 5;
        color: rgb(var(--secondary-text-rbg));
      }
    }

    .promptBox {
      width: 100%;
      height: 144px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 20px;

      .prompt {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        overflow: hidden;
        min-height: 144px;
        border: 1px solid transparent;
        border-radius: 20px;

        &:empty::before {
          content: attr(content);
          color: rgb(var(--secondary-text-rbg));
        }

        &:focus {
          border-color: rgb(var(--primary-color));
        }
      }
    }

    .bottomTips {
      position: absolute;
      right: 16px;
      bottom: 12px;
      color: rgb(var(--secondary-text-rbg));
    }
  }

  .title {
    font-size: calc(var(--font-size-add) + 18px);
    color: var(--text-color);
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .require {
      color: #FF2922;
      display: inline-block;
      margin-left: 4px;
    }

    svg {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .slider {
    margin-top: 40px;
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      width: 120px;
      height: 120px;
    }

    .text {
      opacity: 0.5;
      font-size: calc(var(--font-size-add) + 16px);
      margin-top: 25px;
      color: rgb(var(--secondary-text-rbg));
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: 112px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(245, 245, 245, 0.86);
    border-top: 1px solid var(--border-color);

    .btn {
      margin-right: 30px;

      &.exp {
        background: #FF7B00;
        color: #fff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tipsContent {
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  width: 360px;
}

.tipsFooter {
  margin-top: 30px;
  text-align: right;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}