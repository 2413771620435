.common-parse-session-emoji-icon {
  font-size: 18px;
}
.common-parse-session-image .ant-image-img {
  max-width: 300px;
  min-width: 150px;
  max-height: 300px;
  min-height: 150px;
  object-fit: cover;
  vertical-align: middle;
}
.common-parse-session-mention {
  color: rgb(var(--primary-color));
  font-weight: bold;
  margin-right: 5px;
}
.chat-message-list-item-self .common-parse-session-mention {
  color: #08effe;
}
.common-parse-session-noti,
.chat-message-list-item-recall {
  padding: 22px 16px;
  line-height: 16px;
  color: #999999;
  text-align: center;
}
.common-parse-session-noti-transfer {
  color: #2a6bf2;
  margin-right: 5px;
}
.common-parse-session-audio-container {
  padding: 12px 16px;
  cursor: pointer;
}
.common-parse-session-audio-in,
.common-parse-session-audio-out {
  width: 50px;
  display: flex;
  justify-content: space-between;
}
.common-parse-session-audio-in .common-parse-session-audio-icon-wrapper,
.common-parse-session-audio-out .common-parse-session-audio-icon-wrapper {
  font-size: 20px;
}
.common-parse-session-audio-in .common-parse-session-audio-icon-wrapper:hover,
.common-parse-session-audio-out .common-parse-session-audio-icon-wrapper:hover {
  animation: audio-play 5s;
}
.common-parse-session-audio-in {
  flex-direction: row-reverse;
}
.common-parse-session-audio-in .common-parse-session-audio-icon-wrapper {
  transform: rotate(180deg);
}
.common-parse-session-location-card {
  position: relative;
  background-color: #fff;
  width: 288px;
}
.common-parse-session-location-card .common-parse-session-location-title {
  padding: 8px 10px 0 10px;
}
.common-parse-session-location-card .common-parse-session-location-subTitle {
  padding: 0 10px 8px 10px;
  color: #999999;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.common-parse-session-location-card img {
  width: 100%;
}

.chat-message-list-item-body .common-parse-session-file-box,
.chat-message-list-item-body .common-parse-session-image-container{
  background: #fff;
  border-radius: 4px 20px 20px 20px;
}



.chat-message-list-item-self .common-parse-session-image-container{
  background: rgb(var(--primary-color));
  border-radius: 20px 4px 20px 20px;
}


.chat-message-list-item-self .common-parse-session-file-box{
  background: #fff;
  border-radius: 20px 4px 20px 20px;
}

.common-parse-session-file-info a{
  color: var(--text-color) !important;
  font-size: calc(var(--font-size-add) + 16px);
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.common-parse-session-file-size{
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 14px);
  font-weight: 400;
}

.common-parse-session-file-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.common-parse-session-file-box .common-parse-session-file-icon {
  font-size: 32px;
}
.common-parse-session-file-box .common-parse-session-file-info {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}
.common-parse-session-upload-container {
  position: relative;
}
.common-parse-session-upload-img {
  max-width: 300px;
  min-width: 150px;
  max-height: 300px;
  min-height: 150px;
  object-fit: cover;
  vertical-align: middle;
}
.common-parse-session-upload-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-parse-session-upload-progress {
  cursor: pointer;
}
.common-parse-session-upload-progress .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ffffff;
}
.common-parse-session-reply-wrapper {
  color: #666666;
  padding: 12px 16px;
}
.common-parse-session-reply-wrapper .common-parse-session-text-wrapper {
  padding: 0;
}
.common-parse-session-reply-wrapper .common-parse-session-image-container {
  margin: 0;
}
.common-parse-session-reply-wrapper .common-parse-session-file-box {
  padding: 0;
}

.common-parse-session-map-menu-popover {
  top: 0 !important;
  bottom: 0 !important;
}
.common-parse-session-map-menu {
  width: 60px;
}
.common-parse-session-map-menu a {
  color: #666666;
  display: block;
  text-align: center;
  margin: 10px 0px;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

.action-right{
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.ant-input-clear-icon, .anticon.ant-input-clear-icon{
  color: #00000040;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  transition: color .3s;
  vertical-align: -1px;
}

.ant-input-clear-icon-hidden, .anticon.ant-input-clear-icon-hidden{
  visibility: hidden;
}

.common-group-avatar-select-btn.ant-btn, .common-group-avatar-select-content{
  align-items: center;
  display: flex;
  justify-content: center;
}
.common-group-avatar-select-btn.ant-btn{
  height: fit-content;
  padding: 8px;
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  color: #000000d9;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  line-height: 1.5715;
  padding: 4px 15px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  outline: 0;
  border: none;
}

.common-group-avatar-select-main {
  cursor: pointer;
  width: fit-content;
}

.common-group-avatar-select-main .ant-avatar {
  width: 46px !important;
  height: 46px !important;
}

.common-group-avatar-select-main .ant-avatar .ant-avatar-string {
  line-height: 46px !important;
}

.common-group-avatar-select-wrapper {
  width: fit-content;
}
.common-group-avatar-select-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-group-avatar-select-btn.ant-btn {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.common-group-avatar-select-btn-img {
  width: 100%;
  height: 100%;
}

.ant-input-prefix, .ant-input-suffix{
  align-items: center;
  display: flex;
  flex: none;
}

.ant-input-suffix{
  margin-left: 4px;
}


.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.common-friend-select-group-friendList-content,
.common-friend-select-selected-title,
.common-select-modal-content-l-title,
.common-select-modal-content-r-title{
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 10px;
}
.common-friend-select-selected-title{
  margin-bottom: 10px;
}
.common-select-modal-content{
  margin-top: 20px;
}
.common-friend-select-wrapper,
.common-select-modal-content{
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  width: calc(100% + 80px);
  padding-left: 40px;
  height: 100%;
  border-top: 1px solid #EEEEEE;;
  border-bottom: 1px solid #EEEEEE;
  max-height: 400px;
  overflow: hidden;
  box-sizing: border-box;
  .common-friend-select-left{
    border-right: 1px solid #EEEEEE;
    .common-friend-select-left-content{
      height: calc(100% - 40px);
    }
    .common-friend-select-subtitle-item{
      padding: 10px 0;
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--secondary-text);
      font-weight: bold;
      line-height: 19px;
      border-bottom: 1px solid #EEEEEE;
      width: 250px;
      margin-bottom: 10px;
    }
  }
  .common-friend-select-left,
  .common-friend-select-right,
  .common-select-modal-content-left,
  .common-select-modal-content-right{
    padding: 20px 0 10px;
    box-sizing: border-box;
    flex: 1;
    .common-friend-select-item,
    .common-select-modal-content-item {
      cursor: pointer;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &:last-child{
        margin-bottom: 0;
      }
      .ant-radio-wrapper{
        margin-right: 12px;
      }
      .ant-avatar{
        width: 46px !important;
        height: 46px !important;
        line-height: 46px !important;
        margin-right: 10px;
      }
      .ant-checkbox{
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
        .ant-checkbox-input{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    .common-friend-select-item-label{
      font-size: calc(var(--font-size-add) + 14px);
      color: var(--text-color);
      font-weight: 500;
      line-height: 16px;
    }
  }

  .common-friend-select-right{
    padding-left: 40px;
    .common-friend-select-selected-content{
      height: calc(100% - 40px);
      padding-bottom: 40px;
    }
  }
}
.common-select-modal-content-left{
  border-right: 1px solid #EEEEEE;
}
.common-select-modal-content-right{
  padding-left: 40px !important;
  .common-select-modal-content-chose{
    display: flex;
    align-items: center;
    .ant-avatar{
      width: 46px !important;
      height: 46px !important;
      margin-right: 10px;
    }
    .ant-avatar-string{
      line-height: 46px !important;
      margin-right: 10px;
    }
    .common-select-modal-content-text{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.conversation-list-wrapper .ant-empty{
  text-align: center;
  margin-top: 160px;
}

.conversation-list-wrapper  .ant-empty-description{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  margin-top: 30px;
}

.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}

.common-parse-session-image {
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 20px;
}
.common-parse-session-video {
  max-width: 360px;
  min-width: 180px;
  max-height: 360px;
  min-height: 180px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 20px;
}

.common-select-modal-content-l-list{
  max-height: 400px;
}

.chat-group-create-wrap .ant-form-item .ant-form-item-row{
  display: flex;
  align-items: center;
}

.common-parse-session-file-type{
  width: 56px;
  height: 56px;
  border-radius: 14px;
  background-color: var(--secondary-bg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-message-list-item-self .common-parse-session-file-type{
  order: 1;
  margin-left: 12px;
}

.common-parse-session-file-type .message-file-type-icon{
  width: 30px;
  height: 30px;
}