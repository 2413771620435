.button {
  cursor: pointer;
  border-radius: 20px;
  display: inline-block;
  height: 50px;
  width: 130px;
  font-size: calc(var(--font-size-add) + 16px);
  border: none;

  &.md {
    width: 92px;
    height: 42px;
    border-radius: 16px;
  }

  &.lg {
    height: 50px;
    width: 130px;
    border-radius: 20px;
  }

  &.sm {
    width: 60px;
    height: 26px;
    font-size: calc(var(--font-size-add) + 14px);
  }

  &.primary {
    background-color: rgb(var(--primary-color));
    color: #fff;
  }

  &.outline {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    background-color: transparent;
  }

  &.outline-error {
    border: 1px solid var(--warining-color);
    color: var(--warining-color);
    background-color: transparent;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: rgba(var(--secondary-text-rbg), 0.5) !important;
    color: #fff;
  }

  &.error {
    background-color: var(--warining-color);
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }
}

// @media (prefers-color-scheme: light) {
//   .button {
//     color: var(--text-color);
//   }
// }

// @media (prefers-color-scheme: dark) {
//   .button {
//     color: var(--text-color);
//   }
// }