.chat-content{
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.chat-message-list{
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.chat-message-box{
  width: 960px;
  margin:  0 auto;
  padding-top: 30px;
  box-sizing: border-box;
}

.chat-message-list-tip{
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 14px);
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 30px;
}

.chat-message-list-item-wrap{
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
}

.chat-message-list-item-info{
  display: flex;
}

.chat-message-list-item-avatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}

.chat-message-list-item-avatar .ant-avatar-image{
  width: 50px !important;
  height: 50px !important;
}
.chat-message-list-item-nick{
  font-size: calc(var(--font-size-add) + 12px);
  color: var(--secondary-text);
  font-weight: 400;
  line-height: 21px;
  padding-bottom: 6px;
}

.chat-message-list-tobottom{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}
.chat-message-list-item-self .chat-message-list-item-nick{
  text-align: right;
}

.chat-message-list-item-wrap .chat-message-list-item-content-box .chat-message-list-item-body{
  word-wrap: break-word;
  font-size: calc(var(--font-size-add) + 14px);
  line-height: 22px;
  max-width: 820px;
  min-width: 50px;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
  text-align: left;
}

.chat-message-list-item-body .common-parse-session-audio-container{
  background: #FFFFFF;
  border-radius: 4px 20px 20px 20px;
  max-width: 100%;
  box-sizing: border-box;
}

.chat-message-list-item-self .chat-message-list-item-body .common-parse-session-audio-container{
  background: rgb(var(--primary-color));
  border-radius: 20px 4px 20px 20px;
}

.common-parse-session-text-wrapper,
.common-only-moblie-msg{
  padding: 18px 16px;
  background: #FFFFFF;
  border-radius: 4px 20px 20px 20px;
  display: inline-block;
  text-align: left;
  user-select: text;
}
.chat-message-list-item-self .common-only-moblie-msg{
  color: var(--text-color);
}

.chat-message-list-item-date{
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 14px);
  padding-bottom: 10px;
  text-align: center;
}

.chat-message-list-item-self .chat-message-list-item-avatar{
  margin-left: 20px;
  margin-right: 0;
}
.chat-message-list-item-self .chat-message-list-item-content-box .chat-message-list-item-body{
  color: #FFFFFF;
  text-align: right;
}
.chat-message-list-item-self .common-parse-session-text-wrapper{
  background: rgb(var(--primary-color));
  border-radius: 20px 4px 20px 20px;
  display: inline-block;
}
.chat-message-list-item-self .chat-message-list-item-content-box{
  align-items: flex-end;
}

.common-parse-session-text-wrapper a{
  color: rgb(var(--primary-color));
}

.chat-message-list-item-self .chat-message-list-item-content-box .chat-message-list-item-body a{
  color: #01FFFF;
}

.chat-message-list-item-self  .chat-message-list-item-info{
  flex-direction: row-reverse;
}

.common-parse-session-reply-wrapper{
  display: block;
  background: #EEEEEE;
  padding: 10px 16px;
  border-radius: 14px;
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  margin-top: 20px;
  text-align: left;
}

.common-parse-session-reply-wrapper .common-parse-session-text-wrapper{
  background: none;
  padding: 0;
  display: inline;
  position: relative;
}

.common-parse-session-reply-nick{
  font-weight: bold;
  display: inline;
}

.common-parse-session-emoji-icon{
  font-size: calc(var(--font-size-add) + 18px);
}

.chat-message-input{
  min-height: 112px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 28px;
  justify-content: center;
  border-top: 1px solid var(--border-color);
}

.chat-message-input-wrap{
  width: 820px;
  min-height: 60px;
  max-height: 400px;
  background-color: #FFFFFF;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  padding: 10px 20px;
  box-sizing: border-box;
  position: relative;
}

.chat-message-input-wrap:focus-within {
  border-color: rgb(var(--primary-color));
}

.chat-message-input-reply-wrap{
  position: relative;
}

.chat-message-input-wrap .chat-message-input-reply-wrap .chat-message-input-reply-container{
  display: flex;
  background: var(--secondary-bg);
  padding: 12px 15px;
  border-radius: 8px;
  margin: 6px 0 4px;
}

.chat-message-input-reply-content{
  border-left: 1px solid #dde0e5;
  flex: 1 1;
  margin-left: 8px;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-message-input-content{
  display: flex;
  box-sizing: border-box;
  position: relative;
  align-items: flex-end;
}

.chat-message-input-wrap .chat-message-input-textarea{
  border: none;
  font-size: calc(var(--font-size-add) + 16px);
  box-sizing: border-box;
  margin-bottom: 6px;
  flex: 1;
}

textarea.ant-input{
  max-width: 100%;
  transition: all .3s, height 0s;
  vertical-align: bottom;
  width: 100%;
}

.ant-input:placeholder-shown{
  text-overflow: ellipsis;
}

.chat-message-input-wrap .chat-message-input-icon-box{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
}

.chat-message-input-icon-upload .ant-upload-disabled .chat-message-input-icon-file{
  cursor: not-allowed;
}

.chat-message-input-emoji-box .ant-popover-inner-content{
  max-height: 327px;
  padding: 16px 14px;
  width: 414px;
}
.chat-message-input-emoji-box .chat-message-input-emoji-item{
  cursor: pointer;
  display: inline-block;
  margin: 0px 10px;
}

.chat-message-input-emoji-box .chat-message-input-emoji-item .chat-message-input-emoji-item-icon{
  font-size: 23px;
}

.common-parse-session-image-container{
  padding: 12px 16px;
}

.chat-message-input-icon-file,
.chat-message-input-icon-emoji{
  width: 30px;
  height: 30px;
  margin-right: 12px;
  cursor: pointer;
}
.chat-message-input-icon-emoji.disabled{
  cursor: not-allowed;
}
.chat-message-input-icon-file:hover path,
.chat-message-input-icon-emoji:hover path{
  fill: rgb(var(--primary-color));
}

.chat-message-input-send-btn{
  width: 40px !important;
  height: 40px !important;
  background-color: rgba(var(--secondary-text-rbg), 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.chat-message-input-send-btn-select{
  background: linear-gradient( 90deg, #5344FF 0%, #9C8BFF 88%);
  box-shadow: 0px 4px 10px 0px rgba(97,84,255,0.4);
}
.chat-action-wrap,
.contact-msg-clear{
  position: absolute !important;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.contact-msg-clear{
  top: 20px;
}
.chat-person-setting-item{
  width: 120px;
  text-align: center;
  margin-right: 40px;
}
.chat-person-setting-item-label{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
  margin-top: 16px;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
  font-weight: 400;
  line-height: 19px;
}
.chat-person-setting-user-wrap{
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 0;
}

.chat-person-setting-add-btn{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
}

.chat-person-setting-add-btn svg path{
  stroke: #999999;
}

.chat-person-setting-msg-remind,
.chat-person-setting-top-fixed{
  display: flex;
  justify-content: space-between;
  padding: 30px 10px;
  align-items: center;
}

.chat-person-setting-remind-label,
.chat-person-setting-fixed-label,
.chat-group-setting-wrap .ant-form-item .ant-form-item-label{
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
  line-height: 19px;
}

.chat-group-setting-item,
.chat-group-setting-wrap .ant-form-item{
  padding: 30px 0;
}

.chat-group-setting-wrap .chat-group-power-who-item{
  padding-top: 30px;
}

.chat-group-setting-wrap .ant-form-item{
  border-bottom: 1px solid #EEEEEE;
}

.chat-group-setting-wrap .ant-form-item:last-of-type{
  border-bottom: none;
}

.chat-group-setting-wrap .chat-group-setting-head{
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  cursor: pointer;
}

.chat-group-setting-head-info .ant-avatar{
  width: 46px !important;
  height: 46px !important;
  .ant-avatar-string{
    line-height: 46px !important;
  }
}

.chat-group-setting-members{
  border-bottom: 1px solid #EEEEEE;
}

.chat-group-setting-label{
  margin-left: 12px;
}

.chat-group-setting-members-info,
.chat-group-setting-wrap .chat-group-setting-head .chat-group-setting-label,
.chat-group-setting-item b,
.chat-group-setting-wrap .chat-group-power-who-item label,
.chat-group-power-action-item label{
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 21px;
  font-weight: bold;
}

.chat-group-power-who-item label{
  margin-bottom: 16px;
  display: block;
}

.chat-group-setting-members-num{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  line-height: 21px;
  vertical-align: middle;
  margin-left: 4px;
}

.chat-group-setting-members-list{
  display: flex;
  margin-top: 12px;
  align-items: center;
  width: 100%;
}

.chat-group-setting-members-list .chat-group-setting-add-btn{
  cursor: pointer;
}

.chat-group-setting-members-list .chat-group-setting-add-btn,
.chat-group-setting-members-list .common-complex-avatar-wrapper{
  margin-right: 8px;
}
.chat-group-setting-members-list .chat-group-setting-add-btn,
.chat-group-setting-members-list .common-complex-avatar-wrapper .ant-avatar-image,
.chat-group-setting-wrap .common-group-avatar-select-main .ant-avatar-image,
.chat-group-list-wrap .chat-group-item-wrap .chat-group-item-avatar-box .ant-avatar-image{
  width: 46px !important;
  height: 46px !important;
}
.chat-group-list-wrap .chat-group-item-wrap .chat-group-item-avatar-box .ant-avatar-image{
  cursor: pointer;
}

.chat-group-setting-members-list .action-right{
  margin-left: auto;
}

.chat-group-setting-wrap .chat-group-setting-nickinteam{
  margin-top: 16px;
}

.chat-group-setting-wrap .chat-group-setting-nickinteam,
.chat-group-detail-form-input{
  border: none;
  display: flex;
  width: 100%;
  padding: 4px 0px;
}

.chat-group-setting-wrap .chat-group-setting-nickinteam .ant-input{
  width: 100%;
  border: none;
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
  font-weight: 400;
}

.chat-group-setting-power{
  border-top: 1px solid #EEEEEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.chat-group-setting-exit-btn,
.chat-group-setting-group-operation{
  align-items: center;
  bottom: 56px;
  display: flex;
  left: 50%;
  padding: 10px;
  position: absolute;
  transform: translateX(-50%);
  color: #FF2922;
  font-weight: 500;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
  cursor: pointer;
}

.chat-group-setting-wrap .common-group-avatar-select-main{
  margin-top: 16px;
}
.ant-input-affix-wrapper.chat-group-detail-form-input{
  margin-top: 16px;
}

.ant-input-affix-wrapper.chat-group-detail-form-input .ant-input-disabled{
  margin-top: 2px;
}
.chat-group-create-wrap .ant-form-item-control-input-content input{
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
  border: none;
}

.ant-form-item-control-input-content .ant-input-disabled{
  color: rgba(var(--secondary-text-rbg), 0.5);
  margin-top: 16px;
  background-color: transparent;
}

.chat-group-detail-wrap.ant-form .chat-group-detail-form-input textarea{
  resize: none;
  margin-top: 16px;
  border: 1px solid rgba(var(--secondary-text-rbg),0.5);
  border-radius: 16px;
  height: 140px;
  padding: 10px;
  box-sizing: border-box;
  color: var(--text-color);
  font-weight: 400;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
}

.chat-group-power-action .chat-group-power-action-item{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.chat-group-list-input{
  padding: 30px 0 0px;
}

.chat-group-list-no-result .ant-empty{
  text-align: center;
  margin-top: 200px;
}

.chat-group-list-no-result .ant-empty .ant-empty-description{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
  line-height: 19px;
}

.chat-group-list-wrap .chat-group-item-wrap{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.chat-group-list-wrap .chat-group-item-wrap .chat-group-item-avatar-box{
  display: flex;
  align-items: center;
  flex: 1;
}


.chat-group-list-wrap .chat-group-item-wrap .chat-group-item-avatar-box .chat-group-item-label{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
  line-height: 19px;
  font-weight: 400;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
}

.search-add-panel-btn {
  width: 52px;
  height: 52px;
  margin-left: 10px;
  background-color: var(--secondary-bg);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    transition: all 0.3s;
  }
}

.search-add-panel-btn.ant-popover-open svg{
  transform: rotate(45deg);
  rect{
    fill: rgb(var(--primary-color));
  }
}

.search-add-list .search-add-item{
  display: flex;
  height: 66px;
  padding: 20px 16px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #EEEEEE;
  align-items: center;
  &:last-of-type{
    border-bottom: none;
  }
  &:hover{
    opacity: 0.8;
  }
  .search-add-item-icon{
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  .search-add-item-content{
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    line-height: 19px;
    font-weight: 500;
  }
}

.search-search-modal-header-close,
.search-add-modal-header-close,
.search-add-create-modal-header-close,
.chat-add-members-header-close,
.common-usercard-header-close,
.common-select-modal-header-close,
.chat-group-create-header-close,
.common-mycard-header-close{
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.search-search-modal-title,
.search-add-modal-title,
.search-add-create-modal-title,
.chat-add-members-title,
.common-select-modal-title,
.chat-group-create-title{
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 20px;
}

.search-add-modal-title{
  margin-bottom: 30px;
}

.search-add-create-modal-title{
  margin-bottom: 24px;
  font-size: calc(var(--font-size-add) + 22px);
}

.search-search-modal-empty{
  margin-top: 30px;
  text-align: center;
  margin-bottom: 20px;
  .ant-empty-description{
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--secondary-text);
    line-height: 19px;
  }
}

.search-search-modal-content{
  margin-top: 30px;
  .search-search-modal-content-section-title{
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--secondary-text);
    line-height: 19px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    padding-left: 20px;
  }
  .search-search-modal-content-section-item{
    margin-top: 20px;
    cursor: pointer;
    .ant-avatar{
      width: 46px !important;
      height: 46px !important;
      line-height: 46px !important;
      margin-right: 12px;
      .ant-avatar-string{
        line-height: 46px !important;
      }
    }
    .search-search-modal-content-section-item-name{
      font-size: calc(var(--font-size-add) + 16px);
      color: var(--text-color);
      line-height: 19px;
      font-weight: 500;
    }
  }
}


.search-search-modal-content-section-item-more-name{
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
}

.search-search-modal-content-section-item-more-name .search-search-modal-nick{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-search-modal-content-section:first-of-type{
  margin-bottom: 20px;
}

.search-search-modal-alias{
  color: var(--text-color);
  font-size: calc(var(--font-size-add) + 16px);
  font-weight: 500;
  line-height: 19px;
}

.search-search-modal-nick{
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 14px);
  line-height: 16px;
  margin-top: 4px;
}

.search-add-modal-footer,
.search-add-create-modal-footer,
.chat-add-members-btns,
.chat-group-create-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.search-add-modal-list-content,
.search-add-modal-content{
  max-height: 400px;
  margin-top: 26px;
  .ant-avatar{
    width: 46px !important;
    height: 46px !important;
    line-height: 46px !important;
    margin-right: 12px;
    .ant-avatar-string{
      line-height: 46px !important;
    }
  }
  .search-add-modal-info{
    display: flex;
    align-items: center;
  }
  .search-add-modal-info-name{
    font-weight: 500;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    line-height: 19px;
    text-align: left;
  }

  .search-add-modal-info-account{
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--secondary-text);
  }
  button{
    margin-left: auto;
  }
}

.search-add-modal-content{
  display: flex;
  margin-bottom: 20px;
  &:last-of-type{
    margin-bottom: 0;
  }
}

.search-add-modal-empty-content{
  margin-top: 20px;
  margin-left: 20px;
  color: #FF2922;
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
  font-weight: 400;
}

.search-add-create-modal-group-name,
.search-add-create-modal-group-avatar{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .ant-avatar{
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
}
.search-add-create-modal-group-name-content,
.search-add-create-modal-group-avatar-content,
.ant-form-item-label{
  width: 80px;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 21px;
  font-weight: 500;
}

.chat-group-create-wrap .ant-form-item{
  margin-bottom: 20px;
}

.chat-group-create-wrap .ant-form-item .ant-avatar{
  width: 40px !important;
  height: 40px !important;
  .ant-avatar-string{
    line-height: 40px !important;
  }
}

.search-add-create-modal-group-name-input,
.chat-group-create-wrap .ant-form-item-control-input-content input{
  width: 334px;
  height: 50px;
  background: var(--secondary-bg);
  border-radius: 16px;
  border: none;
  padding: 16px 20px;
  box-sizing: border-box;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--text-color);
}

.common-usercard-header,
.common-mycard-header{
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  .ant-avatar{
    width: 70px !important;
    height: 70px !important;
    line-height: 70px !important;
    margin-right: 20px;
    .ant-avatar-string{
      line-height: 70px !important;
    }
  }
  .common-usercard-header-nick,
  .common-mycard-header-nick{
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 22px);
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin-right: 10px;
  }
}


.common-usercard-content-form-item,
.common-mycard-content .ant-form-item-row{
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.common-usercard-content-form-item:has(.common-usercard-content-form-item-input){
  margin-bottom: 15px;
}
.common-mycard-content .ant-form-item-row input{
  margin-top: 0 !important;
  border: none;
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
}
.common-usercard-content-form-item label{
  width: 40px;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  line-height: 18px;
  font-weight: bold;
  margin-right: 30px;
}

.common-usercard-content-form-item-text{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  line-height: 18px;
  font-weight: 400;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common-usercard-content-form-item-input{
  border: none;
  flex: 1;
  height: 44px;
  box-sizing: border-box;
  border-radius: 14px;
}

.common-usercard-content-form-item-input:focus-visible{
  border: 1px solid rgb(var(--primary-color));
  padding: 14px;
}

.common-usercard-footer{
  text-align: center;
  margin-bottom: 10px;
}

.common-usercard-header-controls{
  margin-left: auto;
}

.common-usercard-header-controls circle{
  fill: #999999;
}
.chat-message-input-wrap .ant-popover-inner-content{
  width: 100% !important;
  box-sizing: border-box;
  padding: 0 !important;
}
.chat-at-member-wrap{
  width: 100%;
  padding: 10px 0;
  max-height: 240px;
}
.chat-at-member-item{
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
}

.chat-at-member-item:hover{
  background: #F5F5F5;
}

.chat-at-member-item .ant-avatar,
.chat-at-member-all-icon svg{
  width: 30px !important;
  height: 30px !important;
  margin-right: 12px;
}
.chat-at-member-all-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-forward-modal-input{
  width: 100%;
  position: relative;
  display: inline-flex;
  background-color: var(--secondary-bg);
  border-radius: 16px;
  padding: 16px 20px;
  box-sizing: border-box;
  margin: 20px 0;
}

.chat-forward-modal-input input{
  border: none;
  width: 100%;
  background-color: transparent;
}

.common-select-modal-content-close{
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}

.conversation-unread-count{
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  margin-top: 6px;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 0px rgba(186,0,0,0.24);
  background: #FF2922;
  color: #FFFFFF;
  font-size: calc(var(--font-size-add) + 12px);
}

.contact-list-item-unread{
  position: absolute;
  top: -4px;
  right: -10px;
  width: 8px;
  height: 8px;
  background-color: #FF2922;
  border-radius: 50%;
}