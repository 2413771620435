.creationBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .priceBox {
    .priceNum {
      display: flex;
      align-items: center;
      color: var(--text-color);
      font-size: calc(var(--font-size-add) + 16px);
      font-weight: 400;

      .num {
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 18px);
        line-height: 21px;
      }
    }

    .vip {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(var(--font-size-add) + 14px);
      color: rgb(var(--primary-color));
      line-height: 16px;
      margin-top: 4px;
      cursor: pointer;

      svg {
        margin-top: -4px;

        path {
          fill: rgb(var(--primary-color));
        }
      }
    }
  }

  .btnInfo {
    display: flex;
    align-items: center;

    .reset {
      margin-right: 20px;
      color: var(--text-color);
      font-size: calc(var(--font-size-add) + 16px);
      line-height: 19px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }
    }

    .btn {

      svg {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
    }
  }

  &.vip {
    .priceBox {
      .priceNum {
        .num {
          text-decoration: line-through;
        }
      }

      .vip {
        color: #FF7B00;

        svg {
          display: none;
        }
      }
    }

    .btnInfo {
      .btn {
        background: linear-gradient(348deg, #FFB629 0%, #FFE380 100%) !important;
        font-weight: bold;
        font-size: calc(var(--font-size-add) + 16px);
        line-height: 19px;
        color: #A54500;

        &.disabled {
          color: #fff;
          cursor: not-allowed;
          background: transparent !important;
          background-color: rgba(var(--secondary-text-rbg), 0.5) !important;

          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.maintainBox {
  display: flex;
  align-items: center;
  color: #CCCCCC;
  font-size: calc(var(--font-size-add) + 18px);
  justify-content: center;

  .maintainIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}