.contact-group-wrapper,
.contact-msg-wrapper,
.contact-friend-wrapper,
.contact-black-wrapper,
.chat-wrap{
  flex: 1;
  background: rgba(245,245,245,0.86);
  position: relative;
}

.contact-msg-wrapper .contact-msg-title,
.contact-friend-wrapper .contact-friend-title,
.contact-black-wrapper .contact-black-title,
.contact-group-wrapper .contact-group-title,
.chat-header-wrap{
  height: 80px;
  padding: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #DDDDDD;
  font-size: calc(var(--font-size-add) + 18px);
  color: #333333;
  font-weight: bold;
  line-height: 21px;
}

.chat-header-wrap{
  display: flex;
  align-items: center;
}

.chat-header-wrap .chat-header-sub-title{
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  line-height: 16px;
  margin-left: 12px;
}

.contact-group-wrapper .ant-empty, 
.contact-msg-wrapper .ant-empty, 
.contact-friend-wrapper .ant-empty, 
.contact-black-wrapper .ant-empty{
  height: calc(100% - 280px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}
.contact-msg-wrapper .ant-empty{
  height: calc(100% - 360px);
  padding-top: 30px;
}

.contact-group-wrapper .ant-empty .ant-empty-image, 
.contact-msg-wrapper .ant-empty .ant-empty-image, 
.contact-friend-wrapper .ant-empty .ant-empty-image, 
.contact-black-wrapper .ant-empty .ant-empty-image{
  margin-bottom: 40px;
}

.contact-black-wrapper .contact-black-content,
.contact-group-wrapper .contact-group-content,
.contact-msg-content{
  height: calc(100% - 80px);
  padding-top: 30px;
  box-sizing: border-box;
}


.contact-group-wrapper .ant-empty .ant-empty-description, 
.contact-msg-wrapper .ant-empty .ant-empty-description, 
.contact-friend-wrapper .ant-empty .ant-empty-description, 
.contact-black-wrapper .ant-empty .ant-empty-description,
.contact-black-title-remark,
.contact-black-item-label{
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 16px);
  line-height: 19px;
  font-weight: 400;
}

.contact-black-item,
.contact-group-item,
.contact-msg-item{
  width: 600px;
  height: 90px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 0 auto 20px;
  padding: 18px 30px;
  box-sizing: border-box;
  cursor: pointer;
}

.contact-msg-item{
  width: 700px;
  display: flex;
  align-items: center;
}

.contact-msg-item-flex{
  flex: 1;
  display: flex;
  align-items: center;
}

.contact-black-item{
  display: flex;
  align-items: center;
}

.contact-black-item-label{
  flex: 1;
  color: var(--text-color);
}

.contact-black-item:last-child
.contact-group-item:last-child{
  margin-bottom: 0;
}

.ant-badge{
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: #000000d9;
  display: inline-block;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.ant-badge-status{
  line-height: inherit;
  vertical-align:initial;
}

.ant-avatar{
  font-feature-settings: "tnum", "tnum";
  position:relative;
  display: inline-block;
  border-radius: 50%;
  background-color: #ccc;
  white-space: nowrap;
  color: #fff;
}

.ant-avatar-string{
  left: 50%;
  position: absolute;
  transform-origin: 0 center;
}
.contact-black-item .ant-badge,
.contact-msg-item .ant-badge,
.contact-group-item .ant-badge{
  margin-right: 12px;
}

.contact-friend-list{
  margin-top: 20px;
  height: calc(100% - 100px);
  .contact-friend-subtitle{
    width: 600px;
    margin: 0 auto 15px;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 20px);
    color: var(--secondary-text);
    line-height: 23px;
    padding: 10px 0 10px 20px;
    border-bottom: 2px solid #EEEEEE;
  }
  .contact-friend-item{
    padding: 15px 0;
    width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover .contact-friend-item-label{
      color: rgb(var(--primary-color));
    }
    .contact-friend-item-label{
      margin-left: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.contact-friend-content{
  height: 100%;
  overflow: hidden;
}

.common-welcome-wrap{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 24px;
  color: var(--secondary-text);
}

.common-welcome-start{
  font-weight: 400;
  color: var(--secondary-text);
  font-size: calc(var(--font-size-add) + 18px);
  line-height: 21px;
  margin-top: 30px;
}

.common-select-modal-footer{
  text-align: right;
}
.ant-radio{
  position: relative;
  display: inline-block;
}
.ant-radio-wrapper{
  display: inline-flex;
}
.ant-radio-inner{
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: block;
  height: 20px;
  left: 0;
  position: relative;
  top: 0;
  transition: all .3s;
  width: 20px;
  box-sizing: border-box;
}

.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: rgb(var(--primary-color));
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.ant-radio-checked .ant-radio-inner:after{
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.contact-msg-item-state{
  font-size: calc(var(--font-size-add) + 16px);
  color: var(--secondary-text);
  line-height: 19px;
}