.contact {
  width: 340px;
  background-color: var(--background-color);
  border-right: 1px solid var(--border-color);
  height: 100%;

  .search {
    padding: 30px 15px;
    display: flex;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;

    .searchInput {
      width: 100%;
      height: 100%;
    }
  }

  .type {
    display: flex;
    width: 80%;
    margin: 0px auto 20px;
    height: 44px;

    .typeItem {
      flex: 1;
      padding: 0 10px;
      font-size: calc(var(--font-size-add) + 18px);
      color: var(--secondary-text);
      transition: all 0.3s ease;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: 0;
        height: 0;
        transition: all 0.3s ease;
        margin-top: 4px;
      }

      &.active {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 20px);
        font-weight: bold;

        .icon {
          height: 8px;
          width: 18px;
        }
      }

      .text {
        position: relative;

        .unread {
          position: absolute;
          top: -4px;
          right: -10px;
          width: 8px;
          height: 8px;
          background-color: #FF2922;
          border-radius: 50%;
        }
      }
    }
  }

  .chatList,
  .contactList {
    height: calc(100% - 176px);

    &.hidden {
      display: none;
    }
  }
}