.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.child {
  padding: 20px;
  flex-direction: column;
}

.fatherbox {
  flex-direction: column;
}

.boxtext {
  height: 23px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 20px);
  color: var(--text-color);
  text-transform: none;
  margin-bottom: 30px;
}

.contentbox {
  width: 600px;
  height: 110px;
  padding: 20px 30px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px;

  .contenttext {
    height: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--text-color);
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .contentbalance {
    align-items: center;
    justify-content: space-between;
    margin: 18px 0px;

    .textAndicon {
      align-items: center;
      justify-content: center;

      .balancetext {
        // width: 173px;
        height: 40px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: bold;
        font-size: 34px;
        color: var(--text-color);
        line-height: 40px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .balanceicon {
        margin-left: 10px;
        width: 24px;
        height: 24px;
      }
    }

    .balancebutton {
      color: var(--text-reverse-color);
      width: 80px;
      height: 38px;
      background: #565CFA;
      border-radius: 14px 14px 14px 14px
    }
  }

  .contenttips {
    width: 364px;
    height: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--secondary-text);
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.listcontent {
  flex-direction: column;
  width: 25%;
  justify-content: center;
  align-items: center;

  .listcontentbox {
    display: flex;
    width: 100px;
    height: 100px;
    background-color: var(--background-color);
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .listcontenticon {
      width: 54px;
      height: 54px;
    }
  }

  .listcontenttitle {
    // width: 64px;
    height: 19px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: bold;
    font-size: calc(var(--font-size-add) + 16px);
    color: var(--text-color);
    line-height: 19px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 8px;
  }

  .listcontentvalue {
    height: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--text-color);
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.cmodelbox {
  width: 570px;
  // height: 466px;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 30px 40px 0px;
  flex-direction: column;
}


.titletext {
  font-weight: bold;
  font-size: 32px;
  color: #FF2922;
  margin-bottom: 10px;

  span {
    font-size: 20px;
  }
}

.payimgbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
}

.cmodel_closebox {
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: bold;
    font-size: 22px;
    color: #333333;
  }
}

.cmodel_close {
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
}

.loadicon {
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  left: 55px;
  top: 70px;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.payimage {
  width: 128px !important;
  height: 128px !important;
  margin-bottom: 20px;
  margin: 30px 20px 30px 40px;
}

.cmodel_group {
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
}

.cmodel_box {
  width: 170px;
  height: 110px;
  background-color: #f5f5f5;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  margin: 5px 0px;
  position: relative;
  border: 3px solid transparent !important;
  transition: all 0.1s;
}

.cmodel_box.selected {
  border: 3px solid #4B6EFF !important;
}

.corner {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 80px;
  height: 80px;
  border-bottom-right-radius: 28px !important;
  overflow: hidden;
}

.cmodel_checkmark_icon {
  position: absolute;
  bottom: 7px;
  right: 7px;
  width: 20px !important;
  height: 20px !important;
  z-index: 2;
}

.cmodeliconbox {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cmodel_icon {
  width: 18px !important;
  height: 18px !important;
  margin-right: 5px;
}

.cmodel_point {
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}

.cmodel_prices {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
}

.modelbuttonbox {
  margin: 30px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Savebutton {
  background: #03AD14;
  color: var(--text-reverse-color);
  width: 270px !important;
  border-radius: 36px !important;
  margin-left: 10px;
  margin-bottom: 20px;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
}

.checkbox_custom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_custom text {
  font-weight: 400;
  font-size: 14px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox_customs {
  width: 12px;
  height: 12px;
  border: 2px solid #333;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
}

.checkbox_customs.checked {
  background-color: #333;
  border-color: #333;
}

.checkbox_custom Box {
  display: flex;
  align-items: center;
}

.checkbox_custom Box * {
  margin-left: 5px;
}

.agreement_text {
  color: #565CFA;
  /* 链接颜色 */
  cursor: pointer;
  /* 鼠标悬停时显示指针 */
}