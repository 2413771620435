.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.child {
  padding: 20px;
}

.titlebox {
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .avataricon {
    width: 70px;
    height: 70px;
    background-color: var(--background-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 7, 70, 0.1);
    border-radius: 30px;
    padding: 25px;
    margin-bottom: 30px;
  }

  .avatartitle {
    height: 22px;
    font-weight: bold;
    font-size: 26px;
    color: var(--text-color);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 14px;
  }

  .avatarcontent {
    height: 22px;
    font-weight: 400;
    font-size: calc(var(--font-size-add) + 14px);
    color: var(--secondary-text);
    margin-bottom: 80px;
  }
}


.contentbox {
  width: 600px;
  height: 320px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  height: 80px;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  margin: 0px 30px;
  cursor: pointer;
}

.label {
  height: 22px;
  font-weight: bold;
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
}

.righticon {
  width: 24px !important;
  height: 24px !important;
}

.recordbox {
  font-size: 16px;
  color: #333333 !important;

  p {
    margin-bottom: 5px;
  }

  span {
    font-weight: bold;
  }
}

.libox {
  margin-bottom: 20px;
}

.detailbox {
  width: 400px;
  height: 290;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }

  span {
    color: #FF5852;
  }

  input {
    width: 320px;
    height: 56px;
    background: #F5F5F5;
    border: 0px;
    border-radius: 20px;
    margin: 20px 0px;
    font-size: 16px;
    padding: 0px 10px;
  }
}

.detailbox_butbox {
  width: 100%;
  justify-content: flex-end;

}

.detailbox_calbut {
  width: 92px;
  height: 42px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #999999;
}

.detailbox_okbut {
  margin-left: 10px;
  width: 92px;
  height: 42px;
  background: #565CFA;
  border-radius: 16px;
  border: 0px;
  color: #fff;
}