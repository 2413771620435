.allbox {
  display: block !important;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.homebox {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1060px;
  margin: 0px auto;
  padding-top: 80px;
  transition: all 0.3s ease;
  // overflow-y: auto;
}

.titlebox {
  position: relative;

  .titledivider {
    height: 22px;
    width: 0.5px;
    position: absolute;
    left: 55px;
    top: 12px;
    border-right: 2px solid rgba(153, 153, 153, 0.2);
  }
}

.titleinput {
  width: 100% !important;
  height: 56px !important;
  background: #FFFFFF;
  border-radius: 20px;
  border: 0px;
  padding-left: 70px;
  font-size: 16px;
  color: #333;
  border: 1px solid #fff;
}

.titleinput:focus {
  border: 1px solid #565CFA;
}

.titlebut {
  width: 92px !important;
  height: 56px !important;
  color: #FFFFFF;
  background: #565CFA;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.titleicon {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  top: 18px;
  left: 20px;
}

.emptyIcon {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
}

.carousel {
  display: flex;
  overflow: hidden;
  position: relative;

  .switchicon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 45%;
    z-index: 2;
    cursor: pointer;
  }
}

.imgbox {
  width: 520px;
  height: 200px;
  margin-top: 30px;
  margin-right: 20px;
  border-radius: 30px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imgContentBox {
  position: absolute;
  flex-direction: column;
  top: 40px;
  left: 30px;
}

.imgTitle {
  font-weight: bold;
  font-size: 26px;
}

.imgcontent {
  font-weight: 400;
  font-size: 18px;
  margin: 15px 0px 20px;
}

.imgsvgs {
  img {
    width: 30px;
    height: 30px;
    margin-right: 9px;
    border-radius: 10px;
  }
}

.imgboximg {
  width: 520px;
  height: 200px;
  border-radius: 30px;
}

.tagsbox {
  margin: 30px 0px 50px;
}

.tagbox {
  width: 100px;
  height: 46px;
  border-radius: 16px 16px 16px 16px;
  margin-right: 20px;
  border: 1px solid rgba(153, 153, 153, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tagbox:hover {
  border: 1px solid rgba(153, 153, 153, 0.5);
}

.selected {
  border: 1px solid rgba(86, 92, 250, 0.04);
  background-color: rgba(86, 92, 250, 0.14);
}


.tagsicon {
  width: 22px !important;
  height: 22px !important;
}

.tagstext {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  margin: 0px 2px 2px;
}

.tagbox:hover .tagstext {
  color: #565CFA;
}

.selected:hover .tagstext {
  color: #565CFA;
}

.selected .tagstext {
  color: #565CFA;
}

.toolsboxtitle {
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
}

.toolslist {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.notCollect {
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 120px;
    height: 120px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #999999;

    span {
      color: #565CFA;
      cursor: pointer;
    }
  }
}

.flexContainer {
  width: 300px;
  height: 78px;
  padding: 16px;
  margin: 0px 20px 20px 0px;
  background: #FFFFFF;
  border-radius: 20px;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  transform-origin: center;
}

.flexContainer:nth-child(3n) {
  margin-right: 0 !important;
}

.flexContainer:hover {
  transform: scale(1.05);
}

.homevip {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 26px;
  background: linear-gradient(131deg, #FFC14A 12%, #FFA14A 100%);
  border-image: linear-gradient(158deg, rgba(255, 165, 75, 0), rgba(255, 242, 153, 1)) 1 1;
  border-radius: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 22px !important;
    height: 22px !important;
  }
}

.homenovip {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 26px;
  background: linear-gradient(131deg, #B0B0B0 0%, #CECECE 50%, #A4A4A4 97%);
  border-radius: 20px 0px 16px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px rgba(211, 211, 211, 0.5);

  img {
    width: 22px !important;
    height: 22px !important;
  }
}

.collectionbox {
  width: 32px !important;
  height: 32px !important;
  background: rgba(51, 51, 51, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px !important;
    height: 22px !important;
  }
}

.avatar {
  width: 78px !important;
  height: 78px !important;
  border-radius: 16px;
  margin-right: 12px;
}

.contentbox {
  width: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.contName {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contSynopsis {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolssearchbox {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  height: calc(100% - 180px);
}

.toolsbotbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  // height: calc(100% - 880px);
}

.toolsbotlist {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-height: 0;
  padding: 10px;
}

.texthomevip {
  width: 26px;
  height: 22px;
  margin-left: 3px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 14px;
  color: #FFFAD9;
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 1px #A85D17;
}

.texthomenovip {
  width: 26px;
  height: 22px;
  margin-left: 3px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 14px;
  color: #E2E2E2;
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 1px #666666;
}

.noChat {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 18px;
    color: rgba(86, 92, 250, 0.3);
  }
}

/* Skeleton.module.css */
.skeletonCard {
  display: flex;
  align-items: center;
  width: 300px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  position: relative;
}

.skeletonIcon {
  width: 60px;
  height: 60px;
  background: #dedede;
  border-radius: 12px;
  margin-right: 10px;
}

.skeletonContent {
  flex: 1;
}

.skeletonTitle {
  width: 150px;
  height: 16px;
  background: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 4px;
}

.skeletonDescription {
  width: 200px;
  height: 12px;
  background: #e0e0e0;
  border-radius: 4px;
}

.skeletonHeart {
  width: 24px;
  height: 24px;
  background: #dedede;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeletonIcon,
.skeletonTitle,
.skeletonDescription,
.skeletonHeart {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 400% 100%;
  animation: loading 1.5s infinite;
}