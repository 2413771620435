.conversation-list-wrapper{
  width: 100%;
  flex: 1 1;
  overflow-y: auto;
}
.conversation-item{
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  align-items: center;
}


.conversation-item .conversation-item-avatar{
  position: relative;
}

.conversation-item .conversation-item-avatar .conversation-group-icon{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.conversation-item .ant-avatar{
  width: 50px !important;
  height: 50px !important;
}
.conversation-item .ant-avatar .ant-avatar-string{
  line-height: 50px !important;
}

.conversation-item-top{
  background-color: #eeeeee;
}

.ant-avatar-image{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  border-radius: 50%;
}

.ant-avatar-image img{
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.conversation-item-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 6px 0 10px;
  flex: 1 1;
  min-width: 0;
}

.conversation-item-content-name{
  font-size: calc(var(--font-size-add) + 18px);
  color: var(--text-color);
  font-weight: 500;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.conversation-item-state-date{
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  line-height: 16px;
  font-weight: 400;
}
.conversation-item-content-msg{
  font-size: calc(var(--font-size-add) + 14px);
  color: var(--secondary-text);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.conversation-item-select{
  background-color: #e9eaff;
}

.conversation-item-select .conversation-item-content-name,
.conversation-item-select .conversation-item-content-msg{
  color: rgb(var(--primary-color));
}

.conversation-item:hover{
  background-color: var(--secondary-bg);
}

.conversation-item-top:hover{
  background-color: var(--border-color);
}

p{
  margin: 0;
}

.contact-list-wrapper .contact-list-item{
  height: 90px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.contact-list-wrapper .contact-list-item .ant-badge{
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 12px;
}

.contact-list-wrapper .contact-list-item .ant-badge .ant-avatar{
  font-size: 50px !important;
}

.contact-list-wrapper .contact-list-item .contact-list-item-label{
  color: #333333;
  font-size: calc(var(--font-size-add) + 18px);
  font-weight: 500;
}

.contact-list-wrapper .contact-list-item-select{
  background-color: #e9eaff;
}

.contact-list-wrapper .contact-list-item-select .contact-list-item-label{
  color: rgb(var(--primary-color));
}