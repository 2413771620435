.currentModel {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 40px;

  .modelImg {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .icon {
    width: 28px;
    height: 28px;
  }

  .name {
    color: var(--text-color);
    font-size: calc(var(--font-size-add) + 14px);
    display: flex;
    align-items: center;

    .arrow {
      margin-left: 6px;
      transition: all 0.2s ease;

      path {
        fill-opacity: 1;
        fill: var(--text-color);
      }
    }
  }

  .choose {
    font-size: calc(var(--font-size-add) + 12px);
    color: var(--secondary-text);
  }

  &.openChoose {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

.chooseModel {
  background-color: var(--background-color);
  border-radius: 20px;
  padding: 20px 10px 20px 20px;
  // padding-bottom: 44px;
  width: 698px;
  box-sizing: border-box;
  position: fixed;
  box-shadow: 0px 6px 20px 0px rgba(0, 7, 70, 0.1);
  z-index: 9;
  right: 40px;
  top: 74px;
  height: 664px;
  overflow: hidden;

  .vipbox {
    width: 100%;
    margin-left: -20px;
    margin-top: -20px;
    height: 22px;
    padding: 20px 30px;
    background: rgba(255, 123, 0, 0.08);
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #DDDDDD;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #FF7B00;
    }
  }

  .notvipbox {
    width: 100%;
    margin-left: -20px;
    margin-top: -10px;
    height: 22px;
    padding: 14px 20px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #DDDDDD;

    .notvipbut {
      width: 76px;
      height: 28px;
      margin-left: 20px;
      border-radius: 17px 17px 17px 17px;
      background-color: #fff;
      border: 1px solid #FF7B00;
      font-weight: 500;
      font-size: 14px;
      color: #FF7B00;
      margin-top: -5px;
    }

    svg {
      width: 22px;
      height: 22px;

      path {
        fill: #999;
      }
    }

    p {
      height: 16px;
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #999999;
    }
  }

  .lists {
    padding: 10px 0;
  }

  .groupItem {
    padding-right: 10px;

    .modelGroup {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .logo {
        width: 30px;
        height: 30px;
        margin-right: 4px;
      }

      .groupText {
        color: var(--text-color);
        font-size: calc(var(--font-size-add) + 16px);
        font-weight: bold;
        margin: 0;
      }
    }

    .modelList {
      display: flex;
      flex-wrap: wrap;
    }

    .modelItem {
      border-radius: 20px;
      width: 316px;
      cursor: pointer;
      padding: 14px 18px;
      box-sizing: border-box;
      background-color: var(--secondary-bg);
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;

      &:nth-of-type(odd) {
        margin-right: 20px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top {
          display: flex;
          align-items: center;

          .name {
            color: var(--text-color);
            font-weight: bold;
            font-size: calc(var(--font-size-add) + 16px);
          }

          .vipprice {
            color: rgba(var(--primary-color));
            font-size: calc(var(--font-size-add) + 16px);
            margin-left: 10px;
            text-decoration: line-through;
          }

          .tagIcon {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
        }

        .price {
          color: rgba(var(--primary-color));
          font-size: calc(var(--font-size-add) + 16px);
          margin-left: 10px;
          display: flex;
          align-items: center;

          svg {
            width: 12px;
            height: 12px;
            margin-right: 2px;
            margin-left: 2px;

            path {
              fill: rgba(var(--primary-color));
            }
          }
        }

        .tag,
        .recommend {
          background-color: var(--bg-reverse-color);
          border-radius: 15px;
          color: var(--text-reverse-color);
          font-size: calc(var(--font-size-add) + 12px);
          padding: 3px 10px 4px;
          margin-left: 10px;
          white-space: nowrap;

          &.exp {
            background-color: rgb(var(--primary-color));
            border-bottom-left-radius: 0;
          }
        }

        .recommend {
          display: flex;
          align-items: center;
          background: #E51009;

          svg {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
        }

      }

      .desc {
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 14px);
        margin-top: 4px;
        white-space: nowrap;
      }

      &.active {
        background-color: rgb(var(--primary-color));

        .price svg path {
          fill: #fff;
        }

        .name,
        .price,
        .desc {
          color: #fff !important;
        }

        .exp {
          background-color: #fff !important;
          color: rgb(var(--primary-color));
        }

        .tag {
          background-color: #fff !important;
          color: rgb(var(--primary-color));
        }
      }

      &.free {
        .price {
          text-decoration: line-through;
        }
      }

      &.disabled {
        cursor: not-allowed;
        background: #D9D9D9;

        .name {
          color: var(--secondary-text) !important;
        }
      }

      .maintain {
        position: absolute;
        background: #EBEBEB;
        width: 66px;
        height: 18px;
        color: var(--secondary-text);
        font-size: calc(var(--font-size-add) + 10px);
        right: -20px;
        top: 10px;
        text-align: center;
        line-height: 18px;
        transform: rotate(45deg);
      }
    }
  }
}