.allbox {
  flex-direction: column;
  width: 556px;
  height: calc(100% - 30px);
  margin: 30px auto 10px;
}

.titlebox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;

    .titletips {
      color: red;
      margin: 0px 3px;
    }
  }

  .titlemax {
    margin-left: 3px;
  }
}

.textareabox {
  margin-top: 20px;
  padding: 15px 0px;
  width: 100%;
  height: calc(100% - 250px);
  position: relative;
  background: #F5F5F5;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #F5F5F5;

  .loadingbox {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .errtextarea {
    padding: 0px 16px;
    width: calc(100% - 34px);
    background: #F5F5F5;
    border-radius: 20px;
    border: 1px solid #FF2922;
    resize: none;
  }

  .textarea {
    padding: 0px 16px;
    height: 100%;
    min-height: 300px;
    width: calc(100% - 32px);
    // background: #F5F5F5;
    background-color: transparent;
    border-radius: 20px 20px 20px 20px;
    // border: 1px solid #F5F5F5;
    border: 0px solid #F5F5F5;
    resize: none;
    font-size: 16px;
    font-family: Arial, sans-serif;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    }
  }

  // .textarea:focus {
  //   border: 1px solid #565CFA;
  // }

  .tipstext {
    position: absolute;
    bottom: 10px;
    left: 15px;
    color: #FF5852;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
    }

    p {
      height: 26px;
      font-weight: 500;
      font-size: 16px;
      margin-left: 5px;
    }
  }
}

.focused {
  border: 1px solid #565CFA;
}

.uploadBoxx {
  width: 404px;
  height: 130px;
  background: #F5F5F5;
  border-radius: 18px;
  margin: 3px 5px 0px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #999999;

  .loader {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    margin: 0px 30px;
    border-radius: 50%;
    border-top: 4px solid transparent;
    animation: spin 1s linear infinite;
    position: relative;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .defaultbox {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    background: #DDDDDD;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .uploadIcon {
      width: 32px;
      height: 32px;
    }

    .errplusIcon {
      font-weight: 500;
      font-size: 14px;
      color: #FF5852;
    }
  }

  .previewVideo {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }

  .SucessBox {
    justify-content: start;
    align-items: center;
    margin-top: 15px;

    svg {
      width: 14px;
      height: 14px;
    }

    .errsvg {
      width: 22px;
      height: 22px;
      margin-right: 3px;
    }

    .againText {
      font-weight: 500;
      font-size: 16px;
      color: #565CFA;
    }
  }

  .textWithEllipsis {
    width: 200px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }

  .uploadSucess {
    font-weight: 400;
    font-size: 14px;
    color: #09AD19;
  }

  .uploadBoxicon {
    width: 28px !important;
    height: 28px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.uploadBox {
  width: 130px;
  height: 130px;
  background: #F5F5F5;
  border-radius: 18px;
  margin: 3px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  font-size: 14px;
  color: #999999;

  .titletextBox {
    width: 400px;
    height: 100%;
    position: absolute;
    flex-direction: column;
    left: 145px;

    .titleText {
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 123, 0, 0.7);
      margin: 5px 0px 30px;
    }
  }

  .uploadIcon {
    width: 29px;
    height: 29px;
  }

  .plusIcon {
    font-size: 14px;
    color: #999999;
  }

  .errplusIcon {
    margin-top: 3px;
    font-size: 14px;
    color: #FF5852;
  }

  .againText {
    position: absolute;
    right: -100px;
    font-weight: 500;
    font-size: 16px;
    color: #565CFA;
  }

}

.fileUploadInput {
  display: none;
}

.comsBox {
  justify-content: center;
}