.toollistBox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.listBox {
  width: 50%;
  min-width: 556px;
  height: calc(100% - 110px);
  padding-bottom: 110px;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.contentsBox {
  display: contents;
  height: calc(100% - 100px);
}

.allbox {
  flex-direction: column;
  width: 556px;
  margin: 30px auto 10px;
}

.bgbox {
  background-image: url('../../../assets/png/naMingBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.topbox {
  bottom: 0px;
  right: 0px;
  backdrop-filter: blur(30px);
}

.footbutton {
  width: 100%;
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 28px;
  padding-bottom: 34px;
  bottom: 0px;
  right: 0px;
  backdrop-filter: blur(30px);
}

.previewBox {
  width: 50%;
  height: 100%;
}