.content {
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  min-width: 100vh;
}

.overlay {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(0 0 0 / 0.5);
}

.modelbox {
  position: relative;
}

.closeicon {
  position: absolute;
  right: -40px;
  top: -20px;
  width: 34px !important;
  height: 34px !important;
  cursor: pointer;
}

.contentbox {
  width: 736px;
  height: 664px;
  padding: 30px;
  background-image: url('../../assets/img/vipContent.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px 0px 0px 40px;
  justify-content: center;
  flex-direction: column;

  .titlebox {
    margin-bottom: 15px;
    align-items: center;

    .avatarBox {
      width: 46px;
      height: 46px;
      border-radius: 25px;
      border: 2px solid #FFFFFF;
    }

    .avatar {
      width: 46px;
      height: 46px;
    }

    .name {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      margin: 0px 10px;
    }

    .vipbox {
      width: 38px;
      height: 16px;
    }

    .vipdate {
      font-weight: 400;
      font-size: 12px;
      color: #963C01;
      margin-left: 10px;
    }
  }

  .vipcontentBox {
    width: 716px;
    height: 580px;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 30px;
    overflow: hidden;

    .titles {
      font-weight: bold;
      font-size: 16px;
      color: #4A2300;
      margin-bottom: 20px;
    }

    .rechargesBox {
      flex-wrap: wrap;
    }

    .rechargebox {
      width: 154px;
      height: 120px;
      margin-top: 10px;
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      border: 2px solid #FFDAA2;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;

      .rechargeTagBox {
        position: absolute;
        left: 0px;
        top: -10px;
        width: 70px;
        height: 26px;
        background: linear-gradient(270deg, #FFDE69 0%, #FFC166 100%);
        border-radius: 20px 0px 14px 0px;
        align-items: center;
        justify-content: center;

        p {
          font-weight: bold;
          font-size: 12px;
          color: #7D3C00;
          text-align: center;
        }
      }

      .rechargeTitle {
        font-weight: 500;
        font-size: 14px;
        color: #793C04;
      }

      .rechargeNewprice {
        font-weight: bold;
        font-size: 26px;
        color: #333333;

        span {
          font-size: 16px;
        }
      }

      .rechargePrice {
        font-weight: 500;
        font-size: 14px;
        color: #DAAF88;
        text-decoration: line-through;
      }
    }

    .rechargebox:last-child {
      margin-right: 0px;
    }

    .selected {
      background: #FFF2D9;
      border: 2px solid #FF9900;

      .rechargeNewprice {
        color: #FF2922;
      }
    }

    .rightbox {
      margin-right: 38px;
      margin-bottom: 20px;
      justify-content: space-between;

      .iconbox {
        width: 50px;
        height: 50px;
        background: #FFF0D1;
        border-radius: 16px;
        justify-content: center;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .rightContentBox {
        margin-left: 10px;
        flex-direction: column;

        .rightTitles {
          font-weight: bold;
          font-size: 14px;
          color: #793C04;
        }

        .rightcontents {
          width: 140px;
          font-weight: 400;
          font-size: 10px;
          color: rgba(121, 60, 4, 0.5);
          word-wrap: break-word;
          overflow-wrap: break-word;
          white-space: normal;
        }
      }
    }

    .rightbox:last-child {
      margin-right: 0px;
      justify-content: space-between;
    }

    .table {
      width: 676px;
      height: 910px;
      background: #FFE7C4;
      border-radius: 20px;
      border-collapse: collapse;
      overflow: hidden;
    }

    table,
    th,
    td {
      border: 1px solid #fff;
    }

    th,
    td {
      padding: 8px;
      text-align: center;
      align-items: center;
    }

    th {
      text-align: center;
    }

    .notviptext {
      background: #FFF4E3;
    }

    .viptext {
      font-weight: bold;
      color: #CA4900;
    }

    .vipBadge {
      margin-left: 3px;
      width: 38px;
      height: 14px;
    }
  }
}

.listbox {
  width: 160px;
  height: 724px;
  padding: 0px 40px;
  background-image: url('../../assets/img/vipList.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 40px 40px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .listTitle {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }

  .listPrice {
    font-weight: bold;
    font-size: 32px;
    color: #FF2922;
    margin-bottom: 20px;

    span {
      font-size: 20px;
    }
  }

  .codeBox {
    width: 138px;
    height: 138px;
    padding: 10px;
    background: #FFFFFF;
    margin-bottom: 15px;
    border-radius: 10px 10px 10px 10px;
    opacity: 0.95;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    svg {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #793C04;
    }
  }

  .listPay {
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }
  }

  .listTxt {
    font-weight: 400;
    font-size: 12px;
    color: #C6944B;
    text-align: center;

    span {
      color: #565CFA;
    }
  }
}