.personal {
  display: flex;
  height: 100%;

  span {
    color: red;
  }
}

.content {
  overflow-y: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: var(--secondary-bg);

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  }
}